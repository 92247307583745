import Navbar from "../Components/Navbar/Navbar";//nav bar repace header
import '../CSS/styles.css'
import Footer from '../Components/Footer/Footer';
import { motion } from "framer-motion";
import SponsorUsContent from '../Components/SponsorUs/SponsorUsContent';
import SponsorUsForm from '../Components/SponsorUs/SponsorUsForm';

function SponsorUs() {

    //PHONE NUMBER VALIDATION
    

    return (
        <div className="min-h-screen flex flex-col bg-black">
            <Navbar />
            <motion.main
                initial={{ opacity: 0, y: -150 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5, delay: 1 }}
            >
                <SponsorUsContent />
                {/* {SponsorUsForm(formRef, sendEmailTemplate2, fullNameError, fullName, handleFullNameChange, setIsError, mobile, setmobile, isError, emailError, email, handleEmailChange, isSubmitting, stateMessage)} */}
                <SponsorUsForm />
            </motion.main>
            <Footer />
        </div>

    );
}


export default SponsorUs;