import { useState, useRef, ChangeEvent, FormEvent, SetStateAction, Dispatch, useEffect } from "react";
import validator from 'validator';
import emailjs, { send } from '@emailjs/browser';
// import PhoneInput from 'react-phone-input-2';

// import 'react-phone-input-2/lib/style.css';
import SponsorUsField from "./SponsorUsField";

const SponsorUsForm = () => {
    
    const [fullName, setFullName] = useState("");
    const [fullNameError, setFullNameError] = useState(false);

    //FULL NAME VALIDATION
    const handleFullNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        // Regular expression to allow only letters, spaces, and hyphens
        const regex = /^[a-zA-Z\s-]*$/;

        if (regex.test(value)) {
            setFullName(value);
            setFullNameError(false);
        } else {
            setFullNameError(true);
        }
    };


    //PHONE NUMBER VALIDATION
    const [mobile, setMobile] = useState<string>("");
    const [mobileError, setMobileError] = useState<boolean>(false);

    useEffect(() => {
        console.log(mobile);
    }, [mobile])

    const handleMobileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setMobile(value);
        if (validator.isNumeric(value)) {
            setMobileError(false);
        } else {
            setMobileError(true);
        }
    };

    //DESCRIPTION
    const [description, setDescription] = useState("");
    const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    };

    //EMAIL VALIDATION
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmail(value);
        if (validator.isEmail(value)) {
            setEmailError(false);
        } else {
            setEmailError(true);
        }
    };

    //EMAIL.JS SENDING EMAILS
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [stateMessage, setStateMessage] = useState<string|null>(null);

    const formRef = useRef<HTMLFormElement | null>(null);
        
    const sendEmailTemplate2 = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);

        emailjs
            .sendForm(
                process.env.REACT_APP_EMAILJS_SERVICE_ID!,
                'template_l0yivqg',
                e.target as HTMLFormElement,
                process.env.REACT_APP_PUBLIC_KEY
            )
            .then(
                (result) => {
                    console.log(result.text); // Log success message
                    setStateMessage('Your inquiry has been sent');
                    setIsSubmitting(false);
                    setTimeout(() => {
                        setStateMessage(null);
                    }, 5000); // Hide message after 5 seconds

                    // Clear the form fields
                    setFullName('');
                    setMobile('');
                    setEmail('');
                    formRef.current?.reset();
                },
                (error) => {
                    console.error('EmailJS Error:', error); // Log error message
                    setStateMessage('Something went wrong, please try again');
                    setIsSubmitting(false);
                    setTimeout(() => {
                        setStateMessage(null);
                    }, 5000); // Hide message after 5 seconds
                }
            );
    }

    return (     
        <div className="container mx-auto p-4 max-w-4xl sm:px-20 md:px-10 lg:px-10">
            <form ref={formRef} onSubmit={sendEmailTemplate2}>
                <div className="flex flex-col sm:flex-row mb-3 ">
                    <SponsorUsField
                        id='FullName'
                        type="text"
                        label='Company Name / Full Name'
                        placeholder="Enter Full Name"
                        value={fullName}
                        onChange={handleFullNameChange}
                        fieldError={fullNameError}
                        errorText='Full/ Company Name can only contain letters, spaces and hyphens'
                        className="w-full sm:w-1/2 pr-0 sm:pr-2 mb-4 sm:mb-0"
                    />
                    <SponsorUsField
                        id='PhoneNumber'
                        type="tel"
                        label='Phone Number'
                        placeholder="Enter phone number"
                        value={mobile}
                        onChange={handleMobileChange}
                        fieldError={mobileError}
                        errorText='Phone number cannot contain letters, spaces and hyphens'
                        className="w-full sm:w-1/2 pr-0 sm:pr-2 mb-4 sm:mb-0"
                    />

                </div>
                <SponsorUsField
                    id='emailAddress'
                    type="email"
                    label='Your email'
                    placeholder="example@iffa.com"
                    value={email}
                    onChange={handleEmailChange}
                    fieldError={emailError}
                    errorText="Please enter your email address in this format:"
                    className="mt-4"
                />    
                <SponsorUsField 
                    id='description'
                    type="text"
                    label='Description'
                    placeholder="Enter your description"
                    value={description}
                    onChange={handleDescriptionChange}
                    fieldError={false}
                    errorText=""
                    isTextArea={true}
                    className="mt-4"
                />
                <div className='flex flex-wrap gap-4 mt-4 contact-form-check'>
                    <div className="flex items-center mb-4 w-full sm:w-auto">
                        <input id="option1" type="radio" name="option" value="option1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" required />
                        <label htmlFor="option1" className="ml-2 text-sm font-medium text-gray-900 check-text">
                            By clicking "Submit", I agree to marketing Design <a href="/policy" target='_Blank' className="links">Refund Policy</a> and <a href="/policy" target='_Blank' className="links">Privacy Policy</a>
                        </label>
                    </div>
                </div>

                <button
                    type="submit"
                    // value={send}
                    className="mt-4 p-2 float-left text-white rounded-lg submit-button"
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Sending...' : 'Send'}
                </button>

                {stateMessage && (
                    <div className="submission-confirmation">
                        <p style={{ fontSize: '14px', paddingTop: '60px', fontWeight: 'bold', color: stateMessage.includes('wrong') ? 'red' : 'green' }}>
                            {stateMessage}
                        </p>
                    </div>
                )}
            </form>
        </div>
    )
}

export default SponsorUsForm;

type SponsorUsPhoneInputProps = {
    isError: boolean;
    mobile: string;
    setMobile: Dispatch<SetStateAction<string>>;
    setIsError: Dispatch<SetStateAction<boolean>>;
}

// function SponsorUsPhoneInput({...props} :  SponsorUsPhoneInputProps) {
//     return <div className="w-full sm:w-1/2 pl-0 sm:pl-2">
//         <label htmlFor="PhoneNumber"
//             className="block my-2 text-left text-sm font-medium text-gray-900 form-headers">
//             Phone Number <span className='text-red-600'>*</span>
//         </label>
//         <div
//             id='PhoneNumber'
//         >
//             <PhoneInput
//                 country={'au'}
//                 //disableCountryCode={true}
//                 inputStyle={{
//                     width: '100%',
//                     paddingLeft: '45px',
//                     borderRadius: '8px',
//                     borderColor: '#ccc',
//                 }}

//                 containerStyle={{
//                     display: 'flex',
//                     alignItems: 'center'
//                 }}
//                 buttonStyle={{
//                     backgroundColor: '#e0e0e0', // Change this color to your desired background color
//                     borderRadius: '4px', // Optional: Add border-radius for rounded corners
//                 }}

//                 inputClass={`bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-2.5 form-input ${props.isError ? 'border-red-500' : ''}`}
//                 placeholder='Enter Phone Number'

//                 value={props.mobile}
//                 onChange={(value) => {
//                     props.setMobile(value);
//                     if (value.replace(/\D/g, '').length > 11) {
//                         return props.setIsError(true);
//                     }
//                     props.setIsError(false);
//                 } } />
//         </div>

//         {props.isError &&
//             <p style={{ fontSize: '13px', fontWeight: 'normal', color: 'red', paddingTop: '5px' }}>
//                 Phone number should be less than 10 digits
//             </p>}
//     </div>;
// }
