import React, { useState, useEffect, useRef } from "react";
import "./frontpage.css";
import MobileHeroSection from "./MobileHeroSection";
import DesktopHeroSection from "./DesktopHeroSection";
// import BackgroundVids from "../../Videos/Monkey_man.mp4"
// import title from "./title.svg";

// const BackgroundVids = monkeyManTrailer;

const floatVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 1,
            ease: "easeInOut"
        }
    },
    float: {
        y: [0, -10, 0],
        transition: {
            yoyo: Infinity,
            duration: 2,
            ease: "easeInOut"
        }
    }
};

const debounce = (func: (...args: any[]) => void, wait: number) => {
    let timeout: NodeJS.Timeout | null;
    return (...args: any[]) => {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => func(...args), wait);
    };
};

const HeroSection = () => {
    return (
        <>
            <DesktopHeroSection/>
            <MobileHeroSection/>
        </>
        
        
    );
}

export default HeroSection;
