import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import "./SubmissionHome.css";
// import {
//     Film,
//     cinemaMovies,
//     featureFilms,
//     internationalAnimation,
//     internationalDocumentary,
//     shortFeatureFilms,
//     under18Films
// } from '../../Data/films'; // Import all the film groups
import SwiperComponent from "../SubmissionSlider/SubmissionSlider";

// const filmGroups = [
//     ...cinemaMovies,
//     ...featureFilms,
//     ...internationalAnimation,
//     ...internationalDocumentary,
//     ...shortFeatureFilms,
//     ...under18Films
// ];

function SubmissionsHome() {
    // const filmsPerPage = 20;
    // const [currentPage, setCurrentPage] = useState(1);

    // const totalPages = Math.ceil(filmGroups.length / filmsPerPage);
    // const startIdx = (currentPage - 1) * filmsPerPage;
    // const endIdx = startIdx + filmsPerPage;
    // const currentFilms = filmGroups.slice(startIdx, endIdx);

    // const [hoveredFilm, setHoveredFilm] = useState<Film | null>(null);
    // const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    // const navigate = useNavigate();

    // const handleFilmHover = (film: Film, e: React.MouseEvent<HTMLHeadingElement>) => {
    //     setHoveredFilm(film);
    //     setCursorPosition({ x: e.clientX, y: e.clientY });
    // }

    // const handleMouseLeave = () => {
    //     setHoveredFilm(null);
    // }

    // const handleFilmClick = (film: Film) => {
    //     navigate('/synopsis', { state: { film } });
    // }

    // const fadeInAnimationVariants = {
    //     initial: {
    //         opacity: 0,
    //         y: 100,
    //     },
    //     animate: {
    //         opacity: 1,
    //         y: 0,
    //     }
    // }

    // const hoverAnimationVariants = {
    //     initial: {
    //         opacity: 0,
    //         scale: 0.8,
    //     },
    //     animate: {
    //         opacity: 1,
    //         scale: 1,
    //     }
    // }

    return (
        <div className="SubmissionsHome">
            <main>
                <div className="submissions-header">
                    <h1>SUBMISSIONS 2024</h1>
                    <Link to="/submission">See All &gt;&gt;</Link>
                </div>
                <div className="SubmissionHome">
                    <SwiperComponent />
                    {/* <div> */}
                        {/* <div className="submissionsHomeLayout">
                            {currentFilms.map((film, index) => (
                                <div key={index} className="film-container">
                                    <motion.img
                                        src={film.poster}
                                        alt={`${film.name} Poster`}
                                        onMouseEnter={(e) => handleFilmHover(film, e)}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={() => handleFilmClick(film)}
                                        variants={fadeInAnimationVariants}
                                        initial="initial"
                                        whileInView="animate"
                                        viewport={{ once: true }}
                                        whileHover={{
                                            scale: 1.1
                                        }}
                                        className="film-poster"
                                    />
                                    {/* <motion.h1
                                        onMouseEnter={(e) => handleFilmHover(film, e)}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={() => handleFilmClick(film)}
                                        variants={fadeInAnimationVariants}
                                        initial="initial"
                                        whileInView="animate"
                                        viewport={{ once: true }}
                                        whileHover={{
                                            scale:1.3
                                        }}
                                    >
                                        {film.name.toUpperCase()}
                                    </motion.h1> */}
                                {/* </div>
                            ))}
                        </div> */}
                    {/* </div> */}
                </div>
                {/* {hoveredFilm && (
                    <motion.div
                        className="hovered-poster"
                        style={{ top: cursorPosition.y + 10, left: cursorPosition.x + 10 }}
                        initial="initial"
                        animate="animate"
                        variants={hoverAnimationVariants}
                    >
                        <img src={hoveredFilm.poster} alt={`${hoveredFilm.name} Poster`} />
                    </motion.div>
                )} */}
            </main>
        </div>
    )
}

export default SubmissionsHome;
