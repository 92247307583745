import React from 'react';
import { backwardArrow, forwardArrow } from '../../Data/export_images';

type PaginationProps = {
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    totalPages: number;
}

function Pagination({...props}: PaginationProps) {
    return (
        <div className="flex justify-center mt-10">
            <div className="flex items-center text-white text-2xl">
                {props.currentPage === 1 ? null :
                    <div
                        onClick={() => props.setCurrentPage(props.currentPage - 1)}
                        className="flex items-center justify-center rounded-full hover:border-2 hover:border-white h-7 w-7 bg-white cursor-pointer">
                        <img src={backwardArrow} className="w-4" alt="Forward Arrow" />
                    </div>}
                <div className="flex gap-x-4 mr-4 ml-4">
                    {Array.from({ length: props.totalPages }, (_, index) => (
                        <button
                            className="rounded-md hover:shadow-white hover:shadow-md p-2"
                            key={index + 1}
                            onClick={() => props.setCurrentPage(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
                {props.currentPage === props.totalPages ? null :
                    <div
                        onClick={() => props.setCurrentPage(props.currentPage + 1)}
                        className="flex items-center justify-center rounded-full hover:border-2 hover:border-white h-7 w-7 bg-white cursor-pointer">
                        <img src={forwardArrow} className="w-4" alt="Forward Arrow" />
                    </div>
                }

            </div>
        </div>
    );
}   


export default Pagination;