import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import Navbar from "../Components/Navbar/Navbar";
import "../CSS/Synopsis.css";
import casts, { MovieCast, createMoviesCastList } from "../Data/casts";
// import {  } from "../Data/casts"; 


function Synopsis() {
  const location = useLocation();
  const { film } = location.state || { film: {} };
  const navigate = useNavigate();

  const [isExpanded, setIsExpanded] = useState(false);

  const handleBackClick = () => {
    navigate("/submission");
  };

  const handleReadMoreClick = () => {
    setIsExpanded(true);
  };

  const handleModalClose = (e: React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLElement).className.includes('modal-background')) {
      setIsExpanded(false);
    }
  };
  

  const handleCastClick = (castMember: Partial<MovieCast>) => {
    const movieCastList = createMoviesCastList(casts);
    const currentMovie = movieCastList.find(movie => movie.movieName.trim().toLowerCase() === film.name.trim().toLowerCase());

    if (currentMovie) {
        // Filter out the clicked castMember to create the otherCastAndCrew array
        const otherCastAndCrew = currentMovie.casts.filter(member => member.castName !== castMember.castName);
        //console.log("otherCastAndCrews11:", otherCastAndCrew);

        // Pass the selected castMember, the filtered otherCastAndCrew, and the movie name
        navigate("/cast", { state: { castMember, otherCastAndCrew, movieName: film.name.trim() } });
    } else {
        console.warn("Movie not found or no casts available");
    }
};

  // Filter the main cast and crew based on the selected film name
  const filteredCastAndCrew = casts.filter(
    (castMember) => castMember.filmName.trim().toLowerCase() === film.name.trim().toLowerCase()
  );

  // Combine the main cast and crew with the otherCastAndCrew members
  const combinedCastAndCrew = filteredCastAndCrew.reduce((acc: Partial<MovieCast>[], castMember) => {
    // Add the main cast member
    acc.push({
      castName: castMember.castName,
      roles: castMember.roles,
      image: castMember.image,
      bio: castMember.bio,
    });

    // Add any other cast and crew members
    if (castMember.otherCastAndCrews && Array.isArray(castMember.otherCastAndCrews)) {
      castMember.otherCastAndCrews.forEach(other => {
        acc.push({
          castName: other.castName,
          roles: other.roles,  
          image: other.image,
          bio: other.bio,
        });
      });
    }


    return acc;
  }, []);
  const encodedBackground = film.background
    .replace(/ /g, "%20")
    .replace(/'/g, "%27") 
    .replace(/&/g, "%26") 
    .replace(/\+/g, "%2B")
    .replace(/#/g, "%23") 
    .replace(/,/g, "%2C") 
    .replace(/:/g, "%3A")  
    .replace(/;/g, "%3B")  
    .replace(/=/g, "%3D") 
    .replace(/\?/g, "%3F") 
    .replace(/@/g, "%40") 
    .replace(/\$/g, "%24") 
    .replace(/!/g, "%21")  
    .replace(/\(/g, "%28") 
    .replace(/\)/g, "%29") 
    .replace(/\*/g, "%2A"); 
  return (
    <div>
      {film.background && (
        <div className="Synopsis"
          style={{
            backgroundImage: `url(${encodedBackground})`,
            
          }}
        >
          <nav className="navbar synopsis">
            <Navbar />
          </nav>
          <button className="GoBackButton" onClick={handleBackClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/></svg>
          </button>
          <div className="SynopsisContent">
            <div className="SynopsisSection">
              <h1>{film.name}</h1>
            </div>
            <div className="SynopsisSection">
              <h2>Film Details</h2>
              <p>Directed by: {film.director?.name || "Director not available"}</p>
              <p>Year of production: {film.year || "Year not available"}</p>
              <p>Country: {film.country || "Country not available"}</p>
              <p>Duration: {film.duration || "Duration not available"}</p>
            </div>
            <div className="SynopsisSection">
              <h2>Synopsis</h2>
              <p>
                {film.synopsis ? (
                  film.synopsis.length > 300 ? (
                    <>
                      {film.synopsis.slice(0, 300)}
                      <span>... </span>
                      <button onClick={handleReadMoreClick} className="read-more-btn">
                        Read more
                      </button>
                    </>
                  ) : (
                    film.synopsis
                  )
                ) : (
                  "Film synopsis not available."
                )}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Modal Section */}
      {isExpanded && (
        <div className="modal-background" onClick={handleModalClose}>
          <div className="modal-content">
            <h2>Full Synopsis</h2>
            <p>{film.synopsis}</p>
          </div>
        </div>
      )}

      <main className="CastAndCrewSection">
        <h2>Cast & Crew</h2>
        <div className="cast-list">
          
          {combinedCastAndCrew.map((castMember, index) => (
            <div key={index} className="cast-member" onClick={() => handleCastClick(castMember)}>
              <div className="cast-image-wrapper">
                <img src={castMember.image} alt={castMember.castName} className="cast-image" />
              </div>
              <p className="cast-name">{castMember.castName}</p>
              
              <p className="cast-role">
                {Array.isArray(castMember.roles) ? castMember.roles.join(' ') : castMember.roles}
              </p> {/* Join roles with a space */}
            </div>
          ))}
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Synopsis;
