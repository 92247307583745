const SponsorUsContent = () => {
    return (
        <div className="flex flex-col items-center bg-black w-full pb-4 pt-5">
            <h1 className='about-heading'>Sponsor Us</h1>
            <p className="sponsor-body-text text-xs md:text-lg lg:text-base text-white sm:px-20 md:px-10 lg:px-60 max-w-[1600px] mx-auto">
                By sponsoring the International Film Festival of Australia, you're not just investing in an event; you're investing in the power of storytelling, cultural exchange, and the transformative impact of film. Join us in celebrating creativity, diversity, and the magic of cinema by becoming a sponsor today.
                <br /><br />
                We offer a selection of sponsorship packages designed to meet the unique needs and objectives of our partners. Whether you're looking to increase brand awareness, engage with a targeted audience, or demonstrate your commitment to the arts, we have a sponsorship package that's right for you.
                <br /><br />
                For more information on sponsorship opportunities and benefits please enquire by clicking on the sponsor us link below. We look forward to partnering with you to make the International Film Festival of Australia a resounding success.
            </p>
            <p className="text-dark-gold text-sm md:text-base lg:text-lg mt-4">
                IFFA 2024 will take place on the 5th of November 2024
            </p>
        </div>
    )

}

export default SponsorUsContent;