import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { animate, motion } from "framer-motion";
// import Header from "../Components/Header/Header.js";
import Footer from '../Components/Footer/Footer';
import CustomSlider from '../Components/CustomSlider/customSlider.js';
// import slideonepic from "../images/slideone.png";
// import slidetwopic from "../images/slidetwo.png";
// import slidethreepic from "../images/slidethree.png";
import { 
    slideOnePic,
    slideTwoPic,
    slideThreePic
} from "../Data/export_images";
// import films from './films'; // Import the films array
import Navbar from "../Components/Navbar/Navbar";
import "../CSS/submissions.css";
import {
    cinemaMovies,
    featureFilms,
    Film,
    internationalAnimation,
    internationalDocumentary,
    shortFeatureFilms,
    under18Films
} from '../Data/films'; // Import all the film groups
import FilmCard from "../Components/Submission/FilmCard";
import { backwardArrow, forwardArrow } from "../Data/export_images";
import Pagination from "../Components/Submission/Pagination";

const images = [slideOnePic, slideTwoPic, slideThreePic];

const filmGroups = [
    ...cinemaMovies,
    ...featureFilms,
    ...internationalAnimation,
    ...internationalDocumentary,
    ...shortFeatureFilms,
    ...under18Films
];

function Submissions() {
    const filmsPerPage = 30;
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(filmGroups.length / filmsPerPage);
    const startIdx = (currentPage - 1) * filmsPerPage;
    const endIdx = startIdx + filmsPerPage;
    const currentFilms = filmGroups.slice(startIdx, endIdx);

    const [hoveredFilm, setHoveredFilm] = useState<Film | null>(null);
    const navigate = useNavigate();

    const handleFilmHover = (film : Film) => {
        setHoveredFilm(film);
    }

    const handleMouseLeave = () => {
        setHoveredFilm(null);
    }

    const handleFilmClick = (film : Film) => {
        navigate('/synopsis', { state: { film } });
    }
    const fadeInAnimationVariants= {
        initial:{
            opacity:0,
            y:100,
        },
        animate: {
            opacity:1,
            y:0,
        }
    }

    return (
        <div className="Submissions">
            <Navbar />
            <main>
                <div>
                    <h1>SUBMISSIONS 2024</h1>
                </div>
                <div>
                    <div>
                        {/* <h2>Cinema Movie</h2> */}
                        <div className="submissions">
                            {currentFilms.map((film, index) => (
                                <FilmCard 
                                    index={index} 
                                    handleFilmHover={handleFilmHover} 
                                    film={film} 
                                    handleMouseLeave={handleMouseLeave} 
                                    handleFilmClick={handleFilmClick} 
                                    fadeInAnimationVariants={fadeInAnimationVariants} 
                                    hoveredFilm={hoveredFilm} 
                                />
                            ))}
                        </div>
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages}
                        />
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default Submissions;

