import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import Navbar from "../Components/Navbar/Navbar";
import FilmCard from "../Components/Submission/FilmCard";
import { Film, featureFilmNominations, featureFilms } from "../Data/films";
import { useState } from "react";
import { hover } from "@testing-library/user-event/dist/hover";



const Nominations = () => {
    const [hoveredFilm, setHoveredFilm] = useState<Film | null>(null);
    const navigate = useNavigate();

    const handleFilmHover = (film : Film) => {
        setHoveredFilm(film);
    }

    const handleMouseLeave = () => {
        setHoveredFilm(null);
    }

    const handleFilmClick = (film : Film) => {
        navigate('/synopsis', { state: { film } });
    }

    const fadeInAnimationVariants= {
        initial:{
            opacity:0,
            y:100,
        },
        animate: {
            opacity:1,
            y:0,
        }
    }
    
    return (
        <section className="bg-black">
            <Navbar />
            <main className="flex flex-col items-center mt-10 mb-10 text-white">
                <h1 className="text-white text-5xl uppercase font-bold">Nominations 2024</h1>
                <div className="flex flex-col mt-20">
                    <h2 className="text-5xl text-light-gold hover:shadow-lg hover:shadow-light-gold rounded-lg p-4 font-bold w-96 ml-64">
                        Feature Films
                    </h2>
                    <div className="flex flex-wrap justify-center">
                        {featureFilmNominations.map((film, index) => (          
                            <FilmCard
                                handleFilmClick={handleFilmClick}
                                handleMouseLeave={handleMouseLeave}
                                handleFilmHover={handleFilmHover}
                                fadeInAnimationVariants={fadeInAnimationVariants}
                                hoveredFilm={hoveredFilm}
                                film={film} index={index}
                            />       
                        ))}
                    </div>              
                </div>    
                
            </main>
            <Footer />
        </section>
    );
};

export default Nominations;