function getMovieImagePath(imagePath: string) {
    return process.env.PUBLIC_URL + 'images/movies/' + `${imagePath}`;
}

const getSponsorImagePath = (imagePath: string) => {
    return process.env.PUBLIC_URL + 'images/sponsors/' + `${imagePath}`;
}

const getOtherImagePath = (imagePath: string) => {
    return process.env.PUBLIC_URL + 'images/others/' + `${imagePath}`;
}

const arbSponsor = getSponsorImagePath("arb.png");
const atSponsor = getSponsorImagePath("AT.png");
const auroraSponsor = getSponsorImagePath("aurora.png");
const bfcSponsor = getSponsorImagePath("bfc.png");
const crownSponsor = getSponsorImagePath("Crown.png");
const fdSponsor = getSponsorImagePath("FD.png");
const hwSponsor = getSponsorImagePath("hw.png");
const imSponsor = getSponsorImagePath("IM.png");
const itSponsor = getSponsorImagePath("it.png");
const pfSponsor = getSponsorImagePath("PF.png");
const productionsSponsor = getSponsorImagePath("productions.png");
const raSponsor = getSponsorImagePath("RA.png");
const rsSponsor = getSponsorImagePath("RS.png");
const suppsSponsor = getSponsorImagePath("supps.png");
const taSponsor = getSponsorImagePath("ta.png");
const tazwinSponsor = getSponsorImagePath("tazwin.jpg");
const unseenSponsor = getSponsorImagePath("unseen.png");
const visualsSponsor = getSponsorImagePath("visuals.png");    

const Betelgeuse_Director = getMovieImagePath("BETELGEUSE/director.jpeg");
const BetelgeusePoster = getMovieImagePath("BETELGEUSE/poster.jpg");
const BetelgeuseBG = getMovieImagePath("BETELGEUSE/bg.png");

// const CABP_Director = getPath("images/Celebration_A_Bliss_Parade/Jonathan Gradiyan.jpg");
const CelebrationABlissParadePoster = getMovieImagePath("Celebration_A_Bliss_Parade/poster.jpg");
const CelebrationABlissParadeDirector = getMovieImagePath("Celebration_A_Bliss_Parade/director.jpg");
const CelebrationABlissParadeProducer_R = getMovieImagePath("Celebration_A_Bliss_Parade/producer_R.jpg");
const CelebrationABlissParadeCinematographer = getMovieImagePath("Celebration_A_Bliss_Parade/cinematographer.jpg");
const CelebrationABlissParadeEditor = getMovieImagePath("Celebration_A_Bliss_Parade/editor.jpg");
const CelebrationABlissParadeMusic = getMovieImagePath("Celebration_A_Bliss_Parade/music.jpg");
const CelebrationABlissParadeDesigner = getMovieImagePath("Celebration_A_Bliss_Parade/designer.jpg");
const CelebrationABlissParadeBG = getMovieImagePath("Celebration_A_Bliss_Parade/bg.jpg");


const HEDirector = getMovieImagePath("HolidayEmail/director.jpg");
const HEPoster = getMovieImagePath("HolidayEmail/poster.png");
const HEBG = getMovieImagePath("HolidayEmail/bg.png")

const BlackFridayPoster = getMovieImagePath("BlackFriday/poster.jpg");
const BlackFridayBG = getMovieImagePath("BlackFriday/bg.png");
const BlackFridayDirector = getMovieImagePath("BlackFriday/director.jpg");
const BlackFridayProducer = getMovieImagePath("BlackFriday/producer.jpg");
const BlackFridayScreenwriter = getMovieImagePath("BlackFriday/screenwriter.jpg");
const BlackFridayActor_B = getMovieImagePath("BlackFriday/actor_B.jpg");
const BlackFridayActor_T = getMovieImagePath("BlackFriday/actor_T.jpg");
const BlackFridayActor_Q = getMovieImagePath("BlackFriday/actor_Q.jpeg");


const GhostTownCricketDirector = getMovieImagePath("GhostTownCricket/director.jpg");
const GhostTownCricketPoster = getMovieImagePath("GhostTownCricket/poster.jpg");
const GhostTownCricketBG = getMovieImagePath("GhostTownCricket/bg.jpg");
const GhostTownCricketProducer_H = getMovieImagePath("GhostTownCricket/producer_H.jpg");
const GhostTownCricketProducer_B = getMovieImagePath("GhostTownCricket/producer_B.jpg");
const GhostTownCricketCinematographer = getMovieImagePath("GhostTownCricket/cinematographer.jpg");
const GhostTownCricketActor_S = getMovieImagePath("GhostTownCricket/actor_S.jpg");
const GhostTownCricketActor_H = getMovieImagePath("GhostTownCricket/actor_H.jpg");


const CabriniPoster = getMovieImagePath("Cabrini/poster.jpg");
const CabriniBG = getMovieImagePath("Cabrini/bg.jpg");

const RobotPoster = getMovieImagePath("ARobotsDream/poster.jpeg");
const RobotBG = getMovieImagePath("ARobotsDream/bg.jpg");
const RobotDirector = getMovieImagePath("ARobotsDream/director.jpg");

const WhereIAmPoster = getMovieImagePath("WhereIAm/poster.png");
const WIADirector_J = getMovieImagePath("WhereIAm/director_J.jpg");
const WIADirector_B = getMovieImagePath("WhereIAm/director_B.jpg");
const WhereIAmBG = getMovieImagePath("WhereIAm/bg.png");

const ThePodPoster = getMovieImagePath("ThePod/poster.jpg");
const ThePodBG = getMovieImagePath("ThePod/bg.jpeg");
const ThePodDirector = getMovieImagePath("ThePod/director.jpg");
const ThePodProducer = getMovieImagePath("ThePod/producer.jpeg");

const BForNaooDirector = getMovieImagePath("BForNaoo/director.jpg");
const BForNaooPoster = getMovieImagePath("BForNaoo/poster.jpg");
const BForNaooBG = getMovieImagePath("BForNaoo/bg.jpg");

const FathersFootstepsBG = getMovieImagePath("FathersFootsteps/bg.jpeg");
const FatherFootstepsPoster = getMovieImagePath("FathersFootsteps/poster.jpg");

const LetMeOutPoster = getMovieImagePath("LetMeOut/poster.jpg");
const LetMeOutBG = getMovieImagePath("LetMeOut/bg.jpg");
const LetMeOutLeadActor = getMovieImagePath("LetMeOut/lead_actor.jpg");
const LetMeOutSoundDesigner = getMovieImagePath("LetMeOut/sound_designer.jpg");

const MindTheCartPoster = getMovieImagePath("MindTheCart/poster.jpeg");
const MindTheCartBG = getMovieImagePath("MindTheCart/bg.jpg");
const MindTheCartDirector_MD = getMovieImagePath("MindTheCart/director_MD.jpeg");
const MindTheCartDirector_NSP = getMovieImagePath("MindTheCart/director_NSP.jpeg");
const MindTheCartSupporting = getMovieImagePath("MindTheCart/supporting.jpeg");
const MindTheCartEditor = getMovieImagePath("MindTheCart/editor.jpeg");


const MyHandsAreShakingPoster = getMovieImagePath("MyHandsAreShaking/poster.jpg");
const MyHandsAreShakingBG = getMovieImagePath("MyHandsAreShaking/bg.jpg");
const MyHandsAreShakingDirector = getMovieImagePath("MyHandsAreShaking/director.jpg");

const RajKapoorsLoniTunesPoster = getMovieImagePath("RajKapoorsLoniTunes/poster.jpg");
const RajKapoorsLoniTunesBG = getMovieImagePath("RajKapoorsLoniTunes/bg.jpg");

const WhyWaitPoster = getMovieImagePath("WhyWait/poster.png");
const WhyWaitBG = getMovieImagePath("WhyWait/bg.jpg");

const ClownPoster = getMovieImagePath("Clown/poster.jpg");
const ClownBG = getMovieImagePath("Clown/bg.jpg");
const ClownDirector = getMovieImagePath("Clown/director.jpg");

const IdolPoster = getMovieImagePath("Idol/poster.jpg");
const IdolBG = getMovieImagePath("Idol/bg.jpg");

const GoodMorningPoster = getMovieImagePath("GoodMorning/poster.jpg");
const GoodMorningBG = getMovieImagePath("GoodMorning/bg.jpg");

const ParolePoster = getMovieImagePath("Parole/poster.jpg");
const ParoleBG = getMovieImagePath("Parole/bg.jpg");
const ParoleDirector = getMovieImagePath("Parole/director.jpg");

const TheHeroOfAdelaidePoster = getMovieImagePath("TheHeroOfAdelaide/poster.jpg");
const TheHeroOfAdelaideBG = getMovieImagePath("TheHeroOfAdelaide/IMG_20240801_171513.jpg");
const TheHeroOfAdelaideDirector = getMovieImagePath("TheHeroOfAdelaide/director.jpg");
const TheHeroOfAdelaideLead = getMovieImagePath("TheHeroOfAdelaide/lead.jpg");
const TheHeroOfAdelaideCinema = getMovieImagePath("TheHeroOfAdelaide/cinema.jpg");
const TheHeroOfAdelaideSupporting = getMovieImagePath("TheHeroOfAdelaide/supporting.jpg");

const TheGarmentologistPoster = getMovieImagePath("TheGarmentologist/poster.jpg");
const TheGarmentologistDirector = getMovieImagePath("TheGarmentologist/director.jpg");
const TheGarmentologistBG = getMovieImagePath("TheGarmentologist/bg.jpg");

const LilithPoster = getMovieImagePath("Lilith/poster.jpg");
const LilithBG = getMovieImagePath("Lilith/bg.jpg");
const LilithDirector = getMovieImagePath("Lilith/director.jpg");
const LilithLeadM = getMovieImagePath("Lilith/leadM.jpg");
const LilithCinematographer = getMovieImagePath("Lilith/cinematographer.jpg");


const OPIAPoster = getMovieImagePath("OPIA/poster.jpg");
const OPIABG = getMovieImagePath("OPIA/bg.jpg");
const OPIADirector = getMovieImagePath("OPIA/director.jpg");
const OPIAProducer = getMovieImagePath("OPIA/producer.jpg");
const OPIALead = getMovieImagePath("OPIA/lead.jpg");
const OPIASupport = getMovieImagePath("OPIA/support.jpg");

const SoleSistaPoster = getMovieImagePath("SoleSista/poster.jpg");
const SoleSistaBG = getMovieImagePath("SoleSista/bg.jpg");
const SoleSistaDirector = getMovieImagePath("SoleSista/director.jpg");
const SoleSistaLead = getMovieImagePath("SoleSista/lead.jpeg");
const SoleSistaSupporting_CM = getMovieImagePath("SoleSista/Supporting_CM.jpeg");
const SoleSistaSupporting_SP = getMovieImagePath("SoleSista/Supporting_SP.jpeg");
const SoleSistaSupporting_CW = getMovieImagePath("SoleSista/Supporting_CW.jpeg");
const SoleSistaSupporting_TP = getMovieImagePath("SoleSista/Supporting_TP.jpeg");
const SoleSistaCinematographer = getMovieImagePath("SoleSista/cinematographer.jpeg");
const SoleSistaEditor = getMovieImagePath("SoleSista/editor.jpeg");


const PapaAurSmartphoneDirector = getMovieImagePath("PapaAurSmartphone/director.jpeg");
const PapaAurSmartphonePoster = getMovieImagePath("PapaAurSmartphone/poster.png");
const PapaAurSmartphoneBG = getMovieImagePath("PapaAurSmartphone/bg.jpg");

const WDSRAPoster = getMovieImagePath("WeDontSayRetardAnymore/poster.jpg");
const WDSRALead = getMovieImagePath("WeDontSayRetardAnymore/lead.jpg");
const WDSRASupporting = getMovieImagePath("WeDontSayRetardAnymore/supporting.jpg");
const WDSRABG = getMovieImagePath("WeDontSayRetardAnymore/TV_Appearance.jpg");

const VanavaaPoster = getMovieImagePath("Vanavaa/poster.jpg");
const VanavaaBG = getMovieImagePath("Vanavaa/bg.png");

const ThePiccadillyPoster = getMovieImagePath("ThePiccadilly/poster.jpeg");
const ThePicadillyBG = getMovieImagePath("ThePiccadilly/bg.jpg");
const ThePicadillyDirector = getMovieImagePath("ThePiccadilly/director.png");
const ThePicadillyProducer = getMovieImagePath("ThePiccadilly/Producer.jpg");
const ThePicadillyLead_Sch = getMovieImagePath("ThePiccadilly/Lead_Sch.jpg");
const ThePicadillyLead_Tch = getMovieImagePath("ThePiccadilly/Lead_Tch.jpg");


const ArchiesPoster = getMovieImagePath("Archies/poster.jpg");
const ArchiesBG = getMovieImagePath("Archies/bg.avif");
const ArchiesDirector = getMovieImagePath("Archies/director.jpg");

const KingdomOfThePlanetOfTheApesPoster = getMovieImagePath("KingdomOfThePlanetOfTheApes/poster.jpg");
const KingdomOfThePlanetOfTheApesBG = getMovieImagePath("KingdomOfThePlanetOfTheApes/background.jpeg");
const KingdomOfThePlanetOfTheApesDirector = getMovieImagePath("KingdomOfThePlanetOfTheApes/director.jpeg");

const Gadar2Poster = getMovieImagePath("Gadar2/poster.jpg");
const Gadar2BG = getMovieImagePath("Gadar2/bg.jpg");
const Gadar2Director = getMovieImagePath("Gadar2/director.jpg");

const ForceOfNaturePoster = getMovieImagePath("ForceOfNature/poster.jpg");
const ForceOfNatureBG = getMovieImagePath("ForceOfNature/bg.jpg");
const ForceOfNatureDirector = getMovieImagePath("ForceOfNature/director.jpg");

const MonkeymanPoster = getMovieImagePath("Monkeyman/poster.jpg");
const MonkeymanBG = getMovieImagePath("Monkeyman/bg.jpg");
const MonkeymanDirector = getMovieImagePath("Monkeyman/director.webp");

const AlBangeriPoster = getMovieImagePath("AlBangeri/poster.jpg");
const AlBangeriBG = getMovieImagePath("AlBangeri/bg.jpg");

const TheWanderingIslandPoster = getMovieImagePath("TheWanderingIsland/poster.jpeg");
const TheWanderingIslandBG = getMovieImagePath("TheWanderingIsland/bg.webp");
const TheWanderingIslandDirector = getMovieImagePath("TheWanderingIsland/director.jpg");

const SexForSurvivalPoster = getMovieImagePath("SexForSurvival/poster.jpg")
const SexForSurvivalBG = getMovieImagePath("SexForSurvival/bg.jpg")
const SexForSurvivalDirector = getMovieImagePath("SexForSurvival/director.jpg")

const UpgradedPoster = getMovieImagePath("Upgraded/poster.jpg")
const UpgradedBG = getMovieImagePath("Upgraded/bg.png")

const UnsungHeroPoster = getMovieImagePath("UnsungHero/poster.jpg")
const UnsungHeroBG = getMovieImagePath("UnsungHero/bg.webp")

const DaalRotiPoster = getMovieImagePath("DaalRoti/poster.jpg")
const DaalRotiBG = getMovieImagePath("DaalRoti/bg.png")

const DreamPoster = getMovieImagePath("Dream/poster.jpg")

const HerculesRecycled2Poster = getMovieImagePath("HerculesRecycled2.0/poster.jpg")
const HR2BG = getMovieImagePath("HerculesRecycled2.0/bg.jpg")

const IRaHPoster = getMovieImagePath("IRaH/poster.jpg")
const IRaHBG = getMovieImagePath("IRaH/background.png")

const MeantimePoster = getMovieImagePath("Meantime/poster.jpg")
const MeantimeBG = getMovieImagePath("Meantime/bg.png")

const PJPoster = getMovieImagePath("PJ/poster.jpg")
const PJBG = getMovieImagePath("PJ/bg.png")

const TaarikhPoster = getMovieImagePath("Taarikh/poster.jpg")
const TaarikhBG = getMovieImagePath("Taarikh/bg.jpg")

const TheNotePoster = getMovieImagePath("TheNote/poster.jpg")
const TheNoteBG = getMovieImagePath("TheNote/bg-note.jpg")

const UndercoverPoster = getMovieImagePath("Undercover/poster.jpg")
const UndercoverBG = getMovieImagePath("Undercover/bg.png")

const WhitePanjabPoster = getMovieImagePath("WhitePanjab/poster.jpg")
const WhitePanjabBG = getMovieImagePath("WhitePanjab/bg.jpg")

const AbahAndHisBandPoster = getMovieImagePath("AbahAndHisBand/poster.jpg")
const AbahAndHisBandBG = getMovieImagePath("AbahAndHisBand/bg.jpg")

const DarwinTheDreamerPoster = getMovieImagePath("DarwinTheDreamer/poster.jpg")
const DarwinBG = getMovieImagePath("DarwinTheDreamer/bg.jpg")

const InHalfPoster = getMovieImagePath("InHalf/poster.jpg")
const InHalfBG = getMovieImagePath("InHalf/bg.jpg")

const QBJNPoster = getMovieImagePath("QuentinBlake'sJackAndNancy/poster.jpg")
const QBJNBG = getMovieImagePath("QuentinBlake'sJackAndNancy/bg.jpg")

const TweenbotPoster = getMovieImagePath("Tweenbot/poster.jpg")
const TweenbotBG = getMovieImagePath("Tweenbot/Tweenbot.jpg")

const BackToRealityPoster = getMovieImagePath("BackToReality/poster.jpg")
const BackToRealityBG = getMovieImagePath("BackToReality/bg.jpg")

const CABTKTPoster = getMovieImagePath("Chernobyl_A_Bomb_That_Keeps_Ticking/poster.jpg")
const CABTKTBG = getMovieImagePath("Chernobyl_A_Bomb_That_Keeps_Ticking/bg.jpg")

const FreelandPoster = getMovieImagePath("Freeland/poster.jpg")
const FreelandBG = getMovieImagePath("Freeland/bg.jpg")

const DreamfinityPoster = getMovieImagePath("Dreamfinity/poster.jpeg")
const DreamfinityBG = getMovieImagePath("Dreamfinity/bg.jpg")

const ChhavaPoster = getMovieImagePath("Chhava/poster.jpg")
const ChhavaBG = getMovieImagePath("Chhava/bg.png")

const ColebrookPoster = getMovieImagePath("Colebrook/poster.jpg")
const ColebrookBG = getMovieImagePath("Colebrook/bg.jpg")

const DreamweaversPoster = getMovieImagePath("Dreamweavers/poster.jpg")
const DreamweaversBG = getMovieImagePath("Dreamweavers/bg.jpg")

const FlintFatiguePoster = getMovieImagePath("FlintFatigue/poster.jpg")
const FlintFatigueBG = getMovieImagePath("FlintFatigue/IMG_0748.jpg")

const GenerationsToComePoster = getMovieImagePath("GenerationsToCome/poster.jpg")
const GenerationsToComeBG = getMovieImagePath("GenerationsToCome/bg.jpg")

const InTheTrenchesPoster = getMovieImagePath("InTheTrenches/poster.jpg")
const InTheTrenchesBG = getMovieImagePath("InTheTrenches/ITT_Still_8.jpg")

const InvisiblesMéxicoPoster = getMovieImagePath("InvisiblesMéxico/poster.jpg")
const InvisiblesMéxicoBG = getMovieImagePath("InvisiblesMéxico/Still_03.jpg.jpg")

const JoyOfArtPoster = getMovieImagePath("JoyOfArt/poster.jpg")
const JoyOfArtBG = getMovieImagePath("JoyOfArt/bg.jpg")

const LaGrandeSignoraPoster = getMovieImagePath("LaGrandeSignora/poster.jpg")
const LaGrandeSignoraBG = getMovieImagePath("LaGrandeSignora/La_Grande_Signora_-Banner.jpg")

const NoorPoster = getMovieImagePath("Noor/poster.jpg")
const NoorBG = getMovieImagePath("Noor/noor-BG.jpg")

const ObsessedPoster = getMovieImagePath("Obsessed/poster.jpg")
const ObsessedBG = getMovieImagePath("Obsessed/obsessedBG.png")

const ParadisePoster = getMovieImagePath("Paradise/poster.jpg")
const ParadiseBG = getMovieImagePath("Paradise/paradise-BG.png")

const RadaTheFilmPoster = getMovieImagePath("RadaTheFilm/poster.jpg")
const RadaTheFilmBG = getMovieImagePath("RadaTheFilm/RadaBG.png")

const RBAMGPoster = getMovieImagePath("RadicsBélaAMegátkozottGitáros/poster.jpg")
const RBAMGBG = getMovieImagePath("RadicsBélaAMegátkozottGitáros/RadicsBG.png")

const RamnamiPoster = getMovieImagePath("RAMNAMI/poster.jpg")
const RamnamiBG = getMovieImagePath("RAMNAMI/ramnamiBG.jpg")

const ReclaimingOurHumanityPoster = getMovieImagePath("ReclaimingOurHumanity/poster.jpg")
const ReclaimingOurHumanityBG = getMovieImagePath("ReclaimingOurHumanity/reclaiming.jpg")

const ShahroorPoster = getMovieImagePath("Shahroor/poster.jpg")
const ShahroorBG = getMovieImagePath("Shahroor/shahroorbg.jpg")

const TheDarksideOfSocietyPoster = getMovieImagePath("TheDarksideOfSOCIETY/poster.jpg")
const TheDarksideOfSocietyBG = getMovieImagePath("TheDarksideOfSOCIETY/darksideOfSociety.png")

const TheGoldenChainOfMercyPoster = getMovieImagePath("TheGoldenChainOfMercy/poster.jpg")
const TheGoldenChainOfMercyBG = getMovieImagePath("TheGoldenChainOfMercy/GoldenChain.jpg")

const TiVulissiMangiariPoster = getMovieImagePath("TiVulissiMangiari/poster.jpg")
const TiVulissiMangiariBG = getMovieImagePath("TiVulissiMangiari/MangiariBG.png")

const VietnamStoryPoster = getMovieImagePath("VietnamStory/poster.jpg")
const VietnamStoryBG = getMovieImagePath("VietnamStory/VietnamStory.png")

const AnatomyOfTheMindPoster = getMovieImagePath("AnatomyOfTheMind/poster.jpg")
const AnatomyOfTheMindBG = getMovieImagePath("AnatomyOfTheMind/bg.png")

const AntarnaadTheVoiceWithinPoster = getMovieImagePath("AntarnaadTheVoiceWithin/poster.jpg")
const AntarnaadTheVoiceWithinBG = getMovieImagePath("AntarnaadTheVoiceWithin/bg.jpg")

const ARTEMPoster = getMovieImagePath("ARTEM/poster.jpg")
const ARTEMBG = getMovieImagePath("ARTEM/bg.png")

const BAAZPoster = getMovieImagePath("BAAZ/poster.jpg")
const BAAZBG = getMovieImagePath("BAAZ/bg.png")

const BlackholePoster = getMovieImagePath("Blackhole/poster.jpg")
const BlackholeBG = getMovieImagePath("Blackhole/bg.jpg")

const BluOlandaPoster = getMovieImagePath("BluOlanda/poster.jpg")
const BluOlandaBG = getMovieImagePath("BluOlanda/bg.jpg")

const CircoloPoster = getMovieImagePath("CIRCOLO/poster.jpg")
const CircoloBG = getMovieImagePath("CIRCOLO/bg.jpg")

const DepressionPoster = getMovieImagePath("DEPRESSION/poster.jpg")
const DepressionBG = getMovieImagePath("DEPRESSION/bg.png")

const DigPoster = getMovieImagePath("Dig/poster.jpg")
const DigBG = getMovieImagePath("Dig/bg.jpg")

const EchoPoster = getMovieImagePath("ECHO/poster.jpg")
const EchoBG = getMovieImagePath("ECHO/bg.jpg")

const FEverythingButLifePoster = getMovieImagePath("FEverythingButLife/poster.jpg")
const FEverythingButLifeBG = getMovieImagePath("FEverythingButLife/bg.jpg")

const FriendlyFirePoster = getMovieImagePath("FriendlyFire/poster.jpg")
const FriendlyFireBG = getMovieImagePath("FriendlyFire/bg.jpg")

const KindnessCrossingPoster = getMovieImagePath("KindnessCrossing/poster.jpg")
const KindnessCrossingBG = getMovieImagePath("KindnessCrossing/bg.jpg")

const KissForMePoster = getMovieImagePath("KissForMe/poster.jpg")
const KissForMeBG = getMovieImagePath("KissForMe/STILLS_2.jpg")

const LasyaSongPoster = getMovieImagePath("Lasya'sSong/poster.jpg")
const LasyaSongBG = getMovieImagePath("Lasya'sSong/bg.png")

const LatitudesPoster = getMovieImagePath("Latitudes/poster.jpg")
const LatitudesBG = getMovieImagePath("Latitudes/bg.png")

const LeftyPoster = getMovieImagePath("Lefty/poster.jpg")
const LeftyBG = getMovieImagePath("Lefty/bg.png")

const ListenToTheSilencePoster = getMovieImagePath("ListenToTheSilence/poster.jpg")
const ListenToTheSilenceBG = getMovieImagePath("ListenToTheSilence/bg.png")

const MarysBookPoster = getMovieImagePath("Mary'sBook/poster.jpg")
const MarysBookBG = getMovieImagePath("Mary'sBook/bg.png")

const MirrorPoster = getMovieImagePath("MIRROR/poster.jpg")
const MirrorBG = getMovieImagePath("MIRROR/bg.jpg")

const PillowTalkPoster = getMovieImagePath("PillowTalk/poster.jpg")
const PillowTalkBG = getMovieImagePath("PillowTalk/bg.png")

const PromotionPoster = getMovieImagePath("Promotion/poster.jpg")
const PromotionBG = getMovieImagePath("Promotion/bg.png")

const ReelTerrorPoster = getMovieImagePath("ReelTerror/poster.jpg")
const ReelTerrorBG = getMovieImagePath("ReelTerror/bg.jpg")

const SaintAndGheevarghesePoster = getMovieImagePath("SaintAndGheevarghese/poster.jpg")
const SaintAndGheevargheseBG = getMovieImagePath("SaintAndGheevarghese/bg.png")

const SardarjiAndAlcoholPoster = getMovieImagePath("SardarjiAndAlcohol/poster.jpg")
const SardarjiAndAlcoholBG = getMovieImagePath("SardarjiAndAlcohol/bg.png")

const ScarsPoster = getMovieImagePath("Scars/poster.jpg")
const ScarsBG = getMovieImagePath("Scars/bg.jpg")

const TheCommunionPoster = getMovieImagePath("TheCommunion/poster.jpg")
const TheCommunionBG = getMovieImagePath("TheCommunion/bg.jpg")

const TheCutPoster = getMovieImagePath("TheCut/poster.jpg")
const TheCutBG = getMovieImagePath("TheCut/bg.jpg")

const TheGraffitiSocietyPoster = getMovieImagePath("TheGraffitiSociety/poster.jpg")
const TheGraffitiSocietyBG = getMovieImagePath("TheGraffitiSociety/default_1.78.1.jpg")

const TheMurderPoster = getMovieImagePath("TheMurder/poster.jpg")
const TheMurderBG = getMovieImagePath("TheMurder/bg.png")

const TheNightsTakingsPoster = getMovieImagePath("TheNight'sTakings/poster.jpg")
const TheNightsTakingsBG = getMovieImagePath("TheNight'sTakings/bg.png")

const TheRiseOfTheWogoloPoster = getMovieImagePath("TheRiseOfTheWogolo/poster.jpg")
const TheRiseOfTheWogoloBG = getMovieImagePath("TheRiseOfTheWogolo/IMGL6573.jpg")

const TheThongPoster = getMovieImagePath("TheThong/poster.jpg")
const TheThongBG = getMovieImagePath("TheThong/Thong_Still2.jpg")

const ZAAGPoster = getMovieImagePath("ZAAG/poster.jpg")
const ZAAGBG = getMovieImagePath("ZAAG/8.jpg")

const ERANPoster = getMovieImagePath("ERAN/poster.jpg")
const ERANBG = getMovieImagePath("ERAN/4.jpg")

const FoundationPoster = getMovieImagePath("Foundation/poster.jpg")
const FoundationBG = getMovieImagePath("Foundation/AnthonyCow_WS.jpg")

const WhoAreWePoster = getMovieImagePath("WhoAreWe/poster.jpg")
const WhoAreWeBG = getMovieImagePath("WhoAreWe/bg.jpg")

const MountainBoyPoster = getMovieImagePath("MountainBoy/poster.webp")
const MountainBoyBG = getMovieImagePath("MountainBoy/IMG_0870.jpg")


const Flags = getOtherImagePath("flags.png");
const slideOnePic = getOtherImagePath("slideone.png");
const slideTwoPic = getOtherImagePath("slidetwo.png");
const slideThreePic = getOtherImagePath("slidethree.png");

const aboutImage1 = getOtherImagePath("IFFAaboutbottompage1.jpeg");
const aboutImage2 = getOtherImagePath("IFFAaboutbottompage2.jpeg");
const aboutImage = getOtherImagePath("IFFFAabout.jpeg");

const IFFALogo = getOtherImagePath("IFFA_Logo_2024.png");
const missingImage = getOtherImagePath("ProfileIcon.svg");

const TitleSVG = getOtherImagePath("title.svg");

const backwardArrow = getOtherImagePath("backward_arrow.png");
const forwardArrow = getOtherImagePath("forward_arrow.png");

const PartnersBackground = getOtherImagePath("partners_background.png");
const MissionsBackground = getOtherImagePath("mission_background.jpg");


export { BetelgeusePoster,
    BetelgeuseBG,
    Betelgeuse_Director,
    CelebrationABlissParadePoster,
    CelebrationABlissParadeBG,
    CelebrationABlissParadeDirector,
    CelebrationABlissParadeProducer_R,
    CelebrationABlissParadeCinematographer,
    CelebrationABlissParadeEditor,
    CelebrationABlissParadeMusic,
    CelebrationABlissParadeDesigner,
    HEDirector,
    HEPoster,
    HEBG,
    BlackFridayPoster,
    BlackFridayBG,
    BlackFridayDirector,
    BlackFridayProducer,
    BlackFridayScreenwriter,
    BlackFridayActor_B,
    BlackFridayActor_T,
    BlackFridayActor_Q,
    GhostTownCricketPoster,
    GhostTownCricketBG,
    GhostTownCricketDirector,
    GhostTownCricketProducer_H,
    GhostTownCricketProducer_B,
    GhostTownCricketCinematographer,
    GhostTownCricketActor_S,
    GhostTownCricketActor_H,
    CabriniPoster,
    CabriniBG,
    RobotPoster,
    RobotBG,
    RobotDirector,
    WhereIAmPoster,
    WIADirector_J,
    WIADirector_B,
    WhereIAmBG,
    ThePodPoster,
    ThePodBG,
    ThePodDirector,
    ThePodProducer,
    BForNaooDirector,
    BForNaooPoster,
    BForNaooBG,
    FathersFootstepsBG,
    FatherFootstepsPoster,
    LetMeOutPoster,
    LetMeOutBG,
    LetMeOutLeadActor,
    LetMeOutSoundDesigner,
    MindTheCartPoster,
    MindTheCartBG,
    MindTheCartDirector_MD,
    MindTheCartDirector_NSP,
    MindTheCartSupporting,
    MindTheCartEditor,
    MyHandsAreShakingPoster,
    MyHandsAreShakingBG,
    MyHandsAreShakingDirector,
    RajKapoorsLoniTunesPoster,
    RajKapoorsLoniTunesBG,
    WhyWaitPoster,
    WhyWaitBG,
    ClownPoster,
    ClownBG,
    ClownDirector,
    IdolPoster,
    IdolBG,
    GoodMorningPoster,
    GoodMorningBG,
    ParolePoster,
    ParoleBG,
    ParoleDirector,
    TheHeroOfAdelaidePoster,
    TheHeroOfAdelaideBG,
    TheHeroOfAdelaideDirector,
    TheHeroOfAdelaideLead,
    TheHeroOfAdelaideCinema,
    TheHeroOfAdelaideSupporting,
    TheGarmentologistPoster,
    TheGarmentologistDirector,
    TheGarmentologistBG,
    LilithPoster,
    LilithBG,
    LilithDirector,
    LilithLeadM,
    LilithCinematographer,
    // OPIADirector,
    OPIAPoster,
    OPIABG,
    OPIADirector,
    OPIAProducer,
    OPIALead,
    OPIASupport,
    SoleSistaPoster,
    SoleSistaBG,
    SoleSistaDirector,
    SoleSistaLead,
    SoleSistaSupporting_CM,
    SoleSistaSupporting_SP,
    SoleSistaSupporting_CW,
    SoleSistaSupporting_TP,
    SoleSistaCinematographer,
    SoleSistaEditor,
    PapaAurSmartphoneDirector,
    PapaAurSmartphonePoster,
    PapaAurSmartphoneBG,
    WDSRAPoster,
    WDSRABG,
    WDSRALead,
    WDSRASupporting,
    VanavaaPoster,
    VanavaaBG,
    ThePiccadillyPoster,
    ThePicadillyBG,
    ThePicadillyDirector,
    ThePicadillyProducer,
    ThePicadillyLead_Sch,
    ThePicadillyLead_Tch,
    ArchiesPoster,
    ArchiesBG,
    ArchiesDirector,
    KingdomOfThePlanetOfTheApesPoster,
    KingdomOfThePlanetOfTheApesBG,
    KingdomOfThePlanetOfTheApesDirector,
    Gadar2Poster,
    Gadar2BG,
    Gadar2Director,
    ForceOfNaturePoster,
    ForceOfNatureBG,
    ForceOfNatureDirector,
    MonkeymanPoster,
    MonkeymanBG,
    MonkeymanDirector,
    AlBangeriPoster,
    AlBangeriBG,
    TheWanderingIslandPoster,
    TheWanderingIslandBG,
    TheWanderingIslandDirector,
    SexForSurvivalPoster,
    SexForSurvivalBG,
    SexForSurvivalDirector,
    UpgradedPoster,
    UpgradedBG,
    UnsungHeroPoster,
    UnsungHeroBG,
    DaalRotiPoster,
    DaalRotiBG,
    DreamPoster as DMPoster,
    HerculesRecycled2Poster as HR2Poster,
    HR2BG,
    IRaHPoster,
    IRaHBG,
    MeantimePoster,
    MeantimeBG,
    PJPoster,
    PJBG,
    TaarikhPoster,
    TaarikhBG,
    TheNotePoster,
    TheNoteBG,
    UndercoverPoster,
    UndercoverBG,
    WhitePanjabPoster,
    WhitePanjabBG,
    AbahAndHisBandPoster,
    AbahAndHisBandBG,
    DarwinTheDreamerPoster as DarwinPoster,
    DarwinBG,
    InHalfPoster,
    InHalfBG,
    QBJNPoster,
    QBJNBG,
    TweenbotPoster,
    TweenbotBG,
    BackToRealityPoster,
    BackToRealityBG,
    CABTKTPoster,
    CABTKTBG,
    FreelandPoster,
    FreelandBG,
    DreamfinityPoster as DreamFinityPoster,
    DreamfinityBG,
    ChhavaPoster,
    ChhavaBG,
    ColebrookPoster,
    ColebrookBG,
    DreamweaversPoster,
    DreamweaversBG,
    FlintFatiguePoster,
    FlintFatigueBG,
    GenerationsToComePoster,
    GenerationsToComeBG,
    InTheTrenchesPoster,
    InTheTrenchesBG,
    InvisiblesMéxicoPoster,
    InvisiblesMéxicoBG,
    JoyOfArtPoster,
    JoyOfArtBG,
    LaGrandeSignoraPoster,
    LaGrandeSignoraBG,
    NoorPoster,
    NoorBG,
    ObsessedPoster,
    ObsessedBG,
    ParadisePoster,
    ParadiseBG,
    RadaTheFilmPoster,
    RadaTheFilmBG,
    RBAMGPoster,
    RBAMGBG,
    RamnamiPoster,
    RamnamiBG,
    ReclaimingOurHumanityPoster,
    ReclaimingOurHumanityBG,
    ShahroorPoster,
    ShahroorBG,
    TheDarksideOfSocietyPoster,
    TheDarksideOfSocietyBG,
    TheGoldenChainOfMercyPoster,
    TheGoldenChainOfMercyBG,
    TiVulissiMangiariPoster,
    TiVulissiMangiariBG,
    VietnamStoryPoster,
    VietnamStoryBG,
    AnatomyOfTheMindPoster,
    AnatomyOfTheMindBG,
    AntarnaadTheVoiceWithinPoster,
    AntarnaadTheVoiceWithinBG,
    ARTEMPoster,
    ARTEMBG,
    BAAZPoster,
    BAAZBG,
    BlackholePoster,
    BlackholeBG,

    arbSponsor,
    atSponsor,
    auroraSponsor,
    bfcSponsor,
    crownSponsor,
    fdSponsor,
    hwSponsor,
    imSponsor,
    itSponsor,
    pfSponsor,
    productionsSponsor,
    raSponsor,
    rsSponsor,
    suppsSponsor,
    taSponsor,
    tazwinSponsor,
    unseenSponsor,
    visualsSponsor,

    Flags,
    forwardArrow,
    backwardArrow,
    slideOnePic,
    slideTwoPic,
    slideThreePic,

    aboutImage1,
    aboutImage2,
    aboutImage,
    IFFALogo,
    missingImage,
    TitleSVG,
    
    BluOlandaPoster,
    BluOlandaBG,
    CircoloPoster,
    CircoloBG,
    DepressionPoster,
    DepressionBG,
    DigPoster,
    DigBG,
    EchoPoster,
    EchoBG,
    FEverythingButLifePoster,
    FEverythingButLifeBG,
    FriendlyFirePoster,
    FriendlyFireBG,
    KindnessCrossingPoster,
    KindnessCrossingBG,
    KissForMePoster,
    KissForMeBG,
    LasyaSongPoster,
    LasyaSongBG,
    LatitudesPoster,
    LatitudesBG,
    LeftyPoster,
    LeftyBG,
    ListenToTheSilencePoster,
    ListenToTheSilenceBG,
    MarysBookPoster,
    MarysBookBG,
    MirrorPoster,
    MirrorBG,
    PillowTalkPoster,
    PillowTalkBG,
    PromotionPoster,
    PromotionBG,
    ReelTerrorPoster,
    ReelTerrorBG,
    SaintAndGheevarghesePoster,
    SaintAndGheevargheseBG,
    SardarjiAndAlcoholPoster,
    SardarjiAndAlcoholBG,
    ScarsPoster,
    ScarsBG,
    TheCommunionPoster,
    TheCommunionBG,
    TheCutPoster,
    TheCutBG,
    TheGraffitiSocietyPoster,
    TheGraffitiSocietyBG,
    TheMurderPoster,
    TheMurderBG,
    TheNightsTakingsPoster,
    TheNightsTakingsBG,
    TheRiseOfTheWogoloPoster,
    TheRiseOfTheWogoloBG,
    TheThongPoster,
    TheThongBG,
    ZAAGPoster,
    ZAAGBG,
    ERANPoster,
    ERANBG,
    FoundationPoster,
    FoundationBG,
    WhoAreWePoster,
    WhoAreWeBG,
    MountainBoyPoster,
    MountainBoyBG,

    PartnersBackground,
    MissionsBackground
};
