import Papa from 'papaparse';
import { useEffect, useState } from "react";
const CSVPath = process.env.PUBLIC_URL + '/data/movieList2.csv'

const TestPage = () => {
  const [data, setData] = useState([])
  useEffect(() => {
    Papa.parse(CSVPath, {
      download: true,
      header: true,
      delimiter: ",",
      complete: function(results) {
        setData(results.data as never[]);
      }
    });
    // console.log(data);
  }, []);

  useEffect(() => {
    console.log(data[0]);
  }, [data])

  return (
    <>
    {/* <SwiperComponent /> */}
    {/* <SubmissionSlider */}
    
    </>
  );
}

export default TestPage;