const getMovieVideoPath = (imagePath: string) => {
    return process.env.PUBLIC_URL + 'videos/' + `${imagePath}`;
}

const MonkeyManTrailer = getMovieVideoPath('MonkeyManTrailer.mp4');
const MonkeyManTrailerCropped = getMovieVideoPath('MonkeyManTrailer_cut.mp4');

export {
    MonkeyManTrailer,
    MonkeyManTrailerCropped
}