import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { TitleSVG } from "../../Data/export_images";
import { MonkeyManTrailerCropped } from "../../Data/export_videos";

const DesktopHeroSection = () => {
    return (
        <div className='relative max-md:hidden'>
            <video
                autoPlay
                loop
                muted
                className="w-full max-lg:h-[500px] max-xl:object-fill"
            >
                <source src={MonkeyManTrailerCropped} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className='absolute inset-0 bg-black opacity-50'/>
            <div className="absolute inset-0 flex flex-col justify-between p-16 xl:p-20">
                <div className="flex flex-col">
                    <motion.img
                        src={TitleSVG}
                        alt="Title"
                        initial="hidden"
                        animate="visible"
                        // variants={floatVariants}
                        whileHover={{ scale: 1.1 }}
                        style={{ willChange: "transform" }} // Ensure smooth rendering
                        className="w-[300px] xl:w-[400px]"
                    />
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        // variants={floatVariants}
                        whileHover={{ scale: 1.15 }}
                        transition={{ type: 'spring', stiffness: 300 }}
                        className="flex items-center justify-center mt-12 xl:mt-16 text-white w-fit px-5 xl:w-64 border-2 border-white text-4xl font-bold text-center py-3 xl:py-6 transition-all duration-500 ease-in-out rounded-xl"
                        style={{ willChange: "transform" }} // Ensure smooth rendering
                        // className
                    >
                        <Link to="https://www.ticketmaster.com.au/international-film-festival-tickets/artist/1347704" className="xl:text-4xl lg:text-3xl text-2xl">Get Tickets</Link>
                    </motion.div>
                </div>
                <div className="flex flex-col items-end text-white text-3xl xl:text-6xl font-bold">
                    <h1>5</h1>
                    <h1>NOVEMBER</h1>
                    <h1>2024</h1>
                </div>
            </div>
        </div>
    )
}

export default DesktopHeroSection;