// casts.js

import { RobotDirector, Betelgeuse_Director, TheGarmentologistDirector, missingImage } from "./export_images"

import {
    LilithDirector, LilithLeadM, LilithCinematographer, 
    LetMeOutLeadActor, LetMeOutSoundDesigner,
    TheHeroOfAdelaideDirector, TheHeroOfAdelaideLead, TheHeroOfAdelaideCinema, TheHeroOfAdelaideSupporting,
    WDSRALead, WDSRASupporting,
    ThePicadillyDirector, ThePicadillyProducer, ThePicadillyLead_Sch, ThePicadillyLead_Tch,
    SoleSistaDirector, SoleSistaLead, SoleSistaSupporting_CM, SoleSistaSupporting_SP, SoleSistaSupporting_CW, SoleSistaSupporting_TP, SoleSistaCinematographer, SoleSistaEditor,
    ThePodDirector, ThePodProducer,
    HEDirector,
    MyHandsAreShakingDirector,
    BlackFridayDirector, BlackFridayProducer, BlackFridayScreenwriter, BlackFridayActor_B, BlackFridayActor_T, BlackFridayActor_Q,
    MindTheCartDirector_MD, MindTheCartDirector_NSP, MindTheCartSupporting, MindTheCartEditor,
    BForNaooDirector,
    ParoleDirector,
    GhostTownCricketDirector, GhostTownCricketProducer_H, GhostTownCricketProducer_B, GhostTownCricketCinematographer, GhostTownCricketActor_S, GhostTownCricketActor_H,
    OPIADirector, OPIAProducer, OPIALead, OPIASupport,
    CelebrationABlissParadeDirector, CelebrationABlissParadeProducer_R, CelebrationABlissParadeCinematographer, CelebrationABlissParadeEditor, CelebrationABlissParadeMusic, CelebrationABlissParadeDesigner
} from "./export_images"

type SocialLinks = {
    instagram?: string;
    facebook?: string;
    twitter?: string;
    youtube?: string;
    linkedin?: string;
    blog?: string;
    website?: string;
};

export type MovieCast = {
    filmName: string;
    castName: string;
    image: string;
    roles: string[] | string;
    quote: string;
    bio: string | {instagram: string};
    socialLinks: SocialLinks;
    otherCastAndCrews?: Partial<MovieCast>[];
};

const casts: MovieCast[] = [
    {
        filmName: "Betelgeuse",
        castName: 'Sunder Konar',
        image: Betelgeuse_Director,
        roles: ['Director', 'Co Producer'],
        quote: `The film "BETELGEUSE" holds profound relevance. It explores the personal journey of Chitra, a young woman grappling with depression, shedding light on the challenges faced by individuals in our society.`,
        bio: `Sunder Konar is an accomplished Assistant Director in the Indian film industry, renowned for his contributions to blockbuster movies such as Kaabil (2017), Dream Girl (2019), Toofaan (2021), Radhe Shyam (2022), and Pippa 1971 India Pakistan war. He has also made a mark in the world of short films and music videos.
        An accomplished boxer and multilingual speaker, Sunder Konar's diverse talents promise to leave a lasting impact in the entertainment industry.`,
        socialLinks: {
            facebook: 'http://facebook.com/quentin',
            twitter: 'http://twitter.com/quentin'
        },
        otherCastAndCrews: [
            {
                castName: 'Ramkumar K',
                image: missingImage,
                roles: ['Producer'],
                bio: `Ram is a dynamic entrepreneur with 15 years of experience in environmental markets. As a founding member and director of sales at RE Connect Energy Solutions, he drives business expansion. Previously, he played a crucial role in setting up Gensol Consultants, now a renowned name. Aside from business, Ram is an avid basketball player and music enthusiast. He follows his passion for film-making and currently produces the short feature "Betalgeuse." Leading the utility business at RE Connect Energy Solutions, Ram's expertise fuels growth and profitability, garnering respect from peers and colleagues, establishing him as a successful film producer and industry leader.`,
            },
            {
                castName: 'Mayur Hardas',
                image: missingImage,
                roles: ['Producer', 'Cinematographer', 'Editor', 'Director Of Photography'],
                bio: `Mayur Hardas is an accomplished Indian movie editor with an impressive portfolio of work, including notable films like "Postcard," "Bioscope," "Mr. & Mrs. Sadachari," "Ranjan," and "Avatarachi Goshta." Hailing from Pune, he commenced his journey in the film industry through short films, eventually directing and producing over 50 of them. With eight years of experience in the Marathi film industry, Mayur independently thrived as an editor and cinematographer. In 2017, he showcased his creativity as a producer in the film "TTMM." Moreover, Mayur directed a Hindi film titled "Dark Light" in 2019. His versatility extends to web series, documentaries, and theater productions, having excelled in ad films and corporate films alongside his filmmaking pursuits. Passionate about exploring various facets of filmmaking, Mayur aspires to continue carving a successful path in the industry and leaves no stone unturned in showcasing his exceptional abilities.`,
            },
            {
                castName: 'Lokesh Kumar',
                image: missingImage,
                roles: ['Screenwriter'],
            },
            {
                castName: 'Gayatri Datar',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Gayatri Datar is a Marathi television actress . Gayatri is known for the serial Tula Pahete Re . Currently she has participated in Bigg Boss Marathi 3 .`,
            },
            {
                castName: 'Ankit Nayar',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `Debut Actor`,
            },
            {
                castName: 'Chitrapama James',
                image: missingImage,
                roles: ['Actor'],
            },
            {
                castName: 'Shiva Chopra',
                image: missingImage,
                roles: ['Actor'],
            },
            {
                castName: 'Manu Malik',
                image: missingImage,
                roles: ['Actor'],
            },
            {
                castName: 'Simran Chandwani',
                image: missingImage,
                roles: ['Actor'],
            },
            {
                castName: 'Lalit Prashar',
                image: missingImage,
                roles: ['Actor'],
            },
            {
                castName: 'Akshay Salve',
                image: missingImage,
                roles: ['Actor'],
            },
            {
                castName: 'Akhil Nayar',
                image: missingImage,
                roles: ['Actor'],
            },
            {
                castName: 'Yeshay phunchok',
                image: missingImage,
                roles: ['Actor'],
            },
            {
                castName: 'Bhuvnesh Thakur',
                image: missingImage,
                roles: ['Actor'],
            },
            {
                castName: 'Akshay Salve',
                image: missingImage,
                roles: ['Editor'],
            },
            {
                castName: 'Arun Raj',
                image: missingImage,
                roles: ['Music Composer'],
                bio: `Kollywood (Tamil) famous- Music Director of #Thadam | #Pizza3 | #Erumbu | #Byri`
            },
            {
                castName: 'Moinac Banerjee',
                image: missingImage,
                roles: ['Production Designer'],
                bio: `Debut`
            },
            // {
            //     name: 'Akhil Nayar',
            //     image: missingImage,
            //     roles: ['Executive Producer'],
            // },
            // {
            //     name: 'Prashant Johari',
            //     image: missingImage,
            //     roles: ['Lyrics'],
            // },
            // {
            //     name: 'Lokesh Singh',
            //     image: missingImage,
            //     roles: ['Writers'],
            // },
            // {
            //     name: 'Mayur Hardas',
            //     image: missingImage,
            //     roles: ['Writers'],
            // },
            // {
            //     name: 'Niha Hardas',
            //     image: missingImage,
            //     roles: ['Costume Designer'],
            // },
            // {
            //     name: 'Niranjana Naiker',
            //     image: missingImage,
            //     roles: ['Accounts Head'],
            // },
            // {
            //     name: 'Shachi Shah',
            //     image: missingImage,
            //     roles: ['1st AC'],
            // },
            // {
            //     name: 'Shubham Katariya',
            //     image: missingImage,
            //     roles: ['2nd AC & Drone Operator'],
            // },
            {
                castName: 'Jerry Mathew',
                image: missingImage,
                roles: ['Assistant Directors'],
            },
            {
                castName: 'Karthik Sethe',
                image: missingImage,
                roles: ['Assistant Directors'],
            },
            {
                castName: 'Gokul Konar',
                image: missingImage,
                roles: ['Assistant Directors'],
            },
            // {
            //     name: 'Urvashi Ajmera',
            //     image: missingImage,
            //     roles: ['Production Coordinator'],
            // },
            {
                castName: 'Sukhamrit Soin',
                image: missingImage,
                roles: ['Singer'],
            }
        ]
    },
    {
        filmName: "Celebration: A Bliss Parade",
        castName: 'Jonathan Gradiyan',
        image: CelebrationABlissParadeDirector,
        roles: ['Director'],
        quote: ``,
        bio: `Jonathan Gradiyan is a young film student that was born in December 2003 and raised in
        Yogyakarta, and now pursuing his passion by continuing his film study at the Jakarta Institute of Arts.
        Although he had done some small projects before enrolling into the Jakarta Institute of Arts. He made his
        first debut as a film director by directing his first proper short film “35 Mm”, by the end of his first semester.
        This film was shot in Yogyakarta, his hometown, and formed by a small scale of crew. Later then, he took
        on plenty projects as a Director of Photography too.`,
        socialLinks: {
            instagram: `https://www.instagram.com/selebrasikebahagiaan?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==
                        https://www.instagram.com/pallapapictures?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==`
        },
        otherCastAndCrews: [
            {
                castName: 'Redemptus Lintang',
                image: CelebrationABlissParadeProducer_R,
                roles: ['Producer'],
                bio: `Redemptus Lintang is a Law Student at Atma Jaya Yogyakarta University. He was born in August
                2004 in Yogyakarta and was raised in Tangerang for a while then went back to Yogyakarta for his studies.
                Although he went to a law school, he shared the same passion as Jonathan, as they have been friends since middle
                school.
                “35 Mm” a film directed by Jonathan Gradiyan was Lintang’s debut in a short movie as well as Jonathan, he
                produced the film in December 2022. Later, he produced “Menghasilkan Bayangan Paling Gelap” that is
                currently still on post. Then by the early of 2024 he produced “Celebration: A Bliss Parade”, a docufiction 
                movie directed by Jonathan Gradiyan.`,
            },
            {
                castName: 'Jonathan Gradiyan',
                image: missingImage,
                roles: ['Screenwriter'],
                bio: `Jonathan Gradiyan is a young film student that was born in December 2003 and raised in
                Yogyakarta, and now pursuing his passion by continuing his film study at the Jakarta Institute of Arts.
                Although he had done some small projects before enrolling into the Jakarta Institute of Arts. He made his
                first debut as a film director by directing his first proper short film “35 Mm”, by the end of his first semester.
                This film was shot in Yogyakarta, his hometown, and formed by a small scale of crew. Later then, he took
                on plenty projects as a Director of Photography too.`,
            },
            {
                castName: 'Josephine Dian',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Josephine Dian is a student at Atma Jaya University of communication major study.
                She’s a professional dancer that has been passionate at very young age. Although not only dancing,
                she has done a project with Viu as an actress during high school.`,
            },
            {
                castName: 'Luftia (Utiya) Yuna Putri',
                image: CelebrationABlissParadeCinematographer,
                roles: ['Cinematographer'],
                bio: `Lutfia Yuna Putri is a young film student from Padang, Indonesia, now studying
                in the Indonesia Institute of Arts Yogyakarta. She’s really passionate about photography and
                cinematography since before entering a film major college.`
            },
            {
                castName: 'Muhammad Arif Rahman',
                image: CelebrationABlissParadeEditor,
                roles: ['Editor'],
                bio: `Arif is a young film student at Jakarta Institute of Arts. During high school he used
                to join in a film club at school, there he begun learning the basics of editing until he tried
                making a short film with a couple of his friends. His interest in editing film grew afterwards
                because of this, he finds that editing in films took an important roles in filming. That is why he
                chose to continue his study at the Jakarta Institute of Arts, and he’s planning to take a major in
                editing.`
            },
            {
                castName: 'Muhhamad Fajar Ghifari',
                image: CelebrationABlissParadeMusic,
                roles: ['Music Composer'],
                bio: `Muhhamad Fajar Ghifari, or as people call him Fajar, is a young film student born
                on the 20th of November 2003 and originated from Bandung. Before attending film school, he       
                had already done some songs with his band. Besides music composing and sound designing he
                is also active as a film director, his work as a director was directing 2 short films, Kicep
                (2024) and Rubbish Boy (2024).`
            },
            {
                castName: 'Bikeska Sanara',
                image: CelebrationABlissParadeDesigner,
                roles: ['Production Designer'],
                bio: `Bikeska is a young film student currently studying in the Jakarta Institute of Arts,
                as a film student, Bikeska spent a lot of time in the art department for. He had plenty of
                experience as a set dresser as well as an assistant for an art director.`
            }
        ]
    },
    {
        filmName: "IDOL",
        castName: 'Suhas Pattathil',
        image: missingImage,
        roles: ['Director'],
        quote: `N/A`,
        bio: `A passionate actor-director-producer, wishing to create films that speak art and fun.`,
        socialLinks: {
            instagram: `https://www.instagram.com/suhaspattathil/`,
            facebook: `https://www.facebook.com/dmpicz`
        },
        otherCastAndCrews: [
            {
                castName: 'Devi Mohan',
                image: missingImage,
                roles: ['Producer'],
                bio: `A small production house that loves art.`,
            },
            {
                castName: 'Mohan Kumar for DM Picz',
                image: missingImage,
                roles: ['Producer'],
                bio: `A small production house that loves art.`,
            },
            {
                castName: 'Suhas Pattathil',
                image: missingImage,
                roles: ['Screenwriter'],
                bio: `A small production house that loves art.`,
            },
            {
                castName: 'Ishani Suhas',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: ``,
            },
            {
                castName: 'Ruth Anumodh',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: ``,
            },
            {
                castName: 'Rebecca Anumodh',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: ``,
            },
            {
                castName: 'Donna Richard',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: {instagram: `https://www.instagram.com/i_m_donna_richard/`},
            },
            {
                castName: 'Elizabeth Varkey',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: {instagram: `https://www.instagram.com/varkey.elizabeth/`},
            },
            {
                castName: 'Anumodh Paul',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: {instagram: `https://www.instagram.com/anumodupaul/`},
            },
            {
                castName: 'Ellen Coote',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: {instagram: `https://www.instagram.com/ellecoote/`},
            },
            {
                castName: 'Avinash Panicker',
                image: missingImage,
                roles: ['Cinematographer'],
                bio: {instagram: `https://www.instagram.com/madmanshooting/`}
            },
            {
                castName: 'Sreejith Jayadevan',
                image: missingImage,
                roles: ['Cinematographer'],
                bio: {instagram: `https://www.instagram.com/madmanshooting/`}
            },
            {
                castName: 'Vinayan M.J.',
                image: missingImage,
                roles: ['Editor'],
                bio: {instagram: `https://www.instagram.com/vinayan_mj/`}
            },
            {
                castName: 'Sidhartha Pradeep',
                image: missingImage,
                roles: ['Music Composer'],
                bio: {instagram: `https://www.instagram.com/sidhartha_pradeep/`}
            },
            {
                castName: 'Suhas Pattathil',
                image: missingImage,
                roles: ['Production Designer'],
                bio: {instagram: `https://www.instagram.com/sidhartha_pradeep/`}
            }
        ]
    },
    {
        filmName: "Clown",
        castName: 'Aarushi Chowdhury',
        image: missingImage,
        roles: ['Director'],
        quote: `N/A`,
        bio: `A passionate actor-director-producer, wishing to create films that speak art and fun.`,
        socialLinks: {
            instagram: `https://www.instagram.com/clown.shortfilm/`
        },
        otherCastAndCrews: [
            {
                castName: 'Adrian Ortega',
                image: missingImage,
                roles: ['Producer'],
                bio: `A small production house that loves art.`,
            },
            {
                castName: 'Aarushi Chowdhury',
                image: missingImage,
                roles: ['Producer'],
                bio: `A small production house that loves art.`,
            },
            {
                castName: 'Diane Vu',
                image: missingImage,
                roles: ['Screenwriter'],
                bio: `A small production house that loves art.`,
            },
            {
                castName: 'Deeya Saxena',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `N/A`,
            },
            {
                castName: 'Shaina Chowdhury',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `N/A`,
            },
            {
                castName: 'Alessia Boschini',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `N/A`,
            },
            {
                castName: 'Marcus Cropp',
                image: missingImage,
                roles: ['Cinematographer'],
                bio: {instagram: `https://www.instagram.com/madmanshooting/`}
            },
            {
                castName: 'Joshua Zhi Lee',
                image: missingImage,
                roles: ['Editor'],
                bio: {instagram: `https://www.instagram.com/vinayan_mj/`}
            },
            {
                castName: 'Matthew Shaw',
                image: missingImage,
                roles: ['Music Composer'],
                bio: `N/A`
            },
            {
                castName: 'Tiffany Dias',
                image: missingImage,
                roles: ['Production Designer'],
                bio: {instagram: `https://www.instagram.com/sidhartha_pradeep/`}
            }
        ]
    },
    {
        filmName: "Father's Footsteps",
        castName: 'Matthew Daherty',
        image: missingImage,
        roles: ['Director', 'Producer', 'Screenwriter', 'Cinematographer', 'Editor'],
        quote: `N/A`,
        bio: `Matthew is a melbourne based filmmaker and academic.`,
        socialLinks: {
            instagram: ``
        },
        otherCastAndCrews: [
            {
                castName: 'Simin Dolatkhah',
                image: missingImage,
                roles: ['Director', 'Producer', 'Screenwriter', 'Production Designer'],
                bio: `A small production house that loves art.`,
            },
            {
                castName: 'Audres Smith',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `N/A`,
            },
            {
                castName: 'Charlie Smith',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `N/A`,
            },
            {
                castName: 'Thomas Smith',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `N/A`,
            },
            {
                castName: 'Sofia Zikic',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `N/A`,
            },
            {
                castName: 'Raky (Dog)',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `N/A`,
            }
        ]
    },
    // {
    //     filmName: "Father's Footsteps",
    //     name: 'Simin Dolatkhah',
    //     image: missingImage,
    //     roles: ['Director', 'Producer', 'Screenwriter', 'Production Designer'],
    //     quote: `N/A`,
    //     bio: ``,
    //     socialLinks: {
    //         instagram: ``
    //     },
    //     otherCastAndCrew: [
    //         {
    //             name: 'Matthew Daherty',
    //             image: missingImage,
    //             roles: ['Director', 'Producer', 'Screenwriter', 'Cinematographer', 'Editor'],
    //             bio: `Matthew is a melbourne based filmmaker and academic.`,
    //         },
    //         {
    //             name: 'Audres Smith',
    //             image: missingImage,
    //             roles: ['Lead Actor'],
    //             bio: `N/A`,
    //         },
    //         {
    //             name: 'Charlie Smith',
    //             image: missingImage,
    //             roles: ['Lead Actor'],
    //             bio: `N/A`,
    //         },
    //         {
    //             name: 'Thomas Smith',
    //             image: missingImage,
    //             roles: ['Supporting Actor'],
    //             bio: `N/A`,
    //         },
    //         {
    //             name: 'Sofia Zikic',
    //             image: missingImage,
    //             roles: ['Supporting Actor'],
    //             bio: `N/A`,
    //         },
    //         {
    //             name: 'Raky (Dog)',
    //             image: missingImage,
    //             roles: ['Supporting Actor'],
    //             bio: `N/A`,
    //         }
    //     ]
    // },
    {
        filmName: "Good Morning",
        castName: 'Anmol Arora',
        image: missingImage,
        roles: ['Director', 'Producer'],
        quote: `N/A`,
        bio: `N/A`,
        socialLinks: {
            instagram: ``
        },
        otherCastAndCrews: [
            {
                castName: 'Umang Shrivashtav',
                image: missingImage,
                roles: ['Screenwriter']
            },
            {
                castName: 'Ashlesha Thakur',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `N/A`,
            },
            {
                castName: 'Petar Senwal',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `N/A`,
            },
            {
                castName: 'Sumit Gulati',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `N/A`,
            },
            {
                castName: 'Ishan Sharma',
                image: missingImage,
                roles: ['Cinematographer']
            },
            {
                castName: 'Ajay Gupta',
                image: missingImage,
                roles: ['Editor']
            },
            {
                castName: 'Vinay Vaishnav',
                image: missingImage,
                roles: ['Editor']
            },
            {
                castName: 'Utam Ravat',
                image: missingImage,
                roles: ['Production Designer']
            }
        ]
    },
    {
        filmName: "A Robot's Dream",
        castName: 'Morteza Halimi',
        image: RobotDirector,
        roles: ['Director', 'Producer', 'Screenwriter', 'Editor'],
        quote: `N/A`,
        bio: ``,
        socialLinks: {
            blog: `https://mortezahalimi.com/film/film_14.html`
        },
        otherCastAndCrews: [
            {
                castName: 'Eon',
                image: missingImage,
                roles: ['Music Composer']
            },
            {
                castName: 'Nick Froud',
                image: missingImage,
                roles: ['Music Composer']
            },
            {
                castName: 'Moby Gratis',
                image: missingImage,
                roles: ['Music Composer']
            }
        ]
    },
    {
        filmName: "Holiday Email",
        castName: 'Ben Nixon',
        image: HEDirector,
        roles: ['Director', 'Cinematographer', 'Editor', 'Production Designer'],
        quote: `N/A`,
        bio: `Perth-based actor and film maker, specialising in stop motion. Studied at SAE Qantm.` ,
        socialLinks: {
            youtube: `@bluestaircaseanimations`, 
            instagram: `@runaway_balloon`
        },
        otherCastAndCrews: [
            {
                castName: 'Matt Nixon',
                image: missingImage,
                roles: ['Poducer'],
                bio: `Perth-based theatre writer, director and actor. Founder of Runaway Balloon Theatre Company.`
            },
            {
                castName: 'Rupert Williamson',
                image: missingImage,
                roles: ['Screenwriter'],
                bio: `Perth-based theatre writer, director and actor. Founder of Runaway Balloon Theatre Company.`
            },
            {
                castName: 'Tim Lorian',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Perth-based theatre director, writer and actor.`
            },
            {
                castName: 'Elsa Silberstein',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `Theatre actor, writer and radio journalist.`
            }
        ]
    },
    {
        filmName: "Black Friday",
        castName: 'Jenna Milly',
        image: BlackFridayDirector,
        roles: ['Director', 'Screenwriter'],
        quote: `N/A`,
        bio: `Known for Golden Arm(2021) and The Fabulous Four(2024) starring Bette Midler and Susan Sarandon, Jenna is a writer, director, producer and went to UCL A Film School.` ,
        socialLinks: {
            instagram: `https://www.instagram.com/blackfridaytheshortfilm/`
        },
        otherCastAndCrews: [
            {
                castName: 'Andrew Dacesare',
                image: BlackFridayProducer,
                roles: ['Poducer'],
                bio: `Andrew has worked with Michael Bay and James Cameron. He is VP of Creative Development at 5150 Action.`
            },
            {
                castName: 'Jenna Milly & Ann Marie Allison',
                image: BlackFridayScreenwriter,
                roles: ['Screenwriter'],
                bio: `This writing team has developed movies from Sony, Netflix and many others and was named 25 Screenwriters to Watch by the Auctin Film Festival in 2021.`
            },
            {
                castName: 'Abbey McBride',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Abbey McBride is known for Veep.`
            },
            {
                castName: 'Bayne Gibby',
                image: BlackFridayActor_B,
                roles: ['Lead Actor'],
                bio: `Bayne Gibby is known for Lady Bird.`
            },
            {
                castName: 'Thomas Forbes-Johnson',
                image: BlackFridayActor_T,
                roles: ['Lead Actor'],
                bio: `Thomas Forbes-Johnson is known for The Loct City.`
            },
            {
                castName: 'Quincy Cho',
                image: BlackFridayActor_Q,
                roles: ['Lead Actor'],
                bio: `Quincy Cho is known for Jimmy Kimmel Live!`
            },
            {
                castName: 'Neil Watson',
                image: missingImage,
                roles: ['Cinematographer'],
                bio: `Neil is from Puerto Rico and has worked on Avatar, Working Title and Rest Button.`
            },
            {
                castName: 'Noah Rosenstein',
                image: missingImage,
                roles: ['Editor']
            },
            {
                castName: 'Marisa Bertani',
                image: missingImage,
                roles: ['Production Designer']
            }
        ]
    },
    {
        filmName: "Ghost Town Cricket",
        castName: 'Matt Sterne',
        image: GhostTownCricketDirector,
        roles: ['Director', 'Screenwriter'],
        quote: `N/A`,
        bio: `https://filmfreeway.com/GhostTownCricket` ,
        socialLinks: {
            website: `www.mattsterne.com / www.uncoveredgrounds.com`
        },
        otherCastAndCrews: [
            {
                castName: 'Henry Richardson, ',
                image: GhostTownCricketProducer_H,
                roles: ['Poducer'],
                bio: `https://www.uncoveredgrounds.com/crew`
            },
            {
                castName: 'Bin Li',
                image: GhostTownCricketProducer_B,
                roles: ['Producer', 'Editor'],
                bio: `https://www.uncoveredgrounds.com/crew`
            },
            {
                castName: 'Sandy Mclellan',
                image: GhostTownCricketActor_S,
                roles: ['Lead Actor']
            },
            {
                castName: 'Hayden Guildford',
                image: GhostTownCricketActor_H,
                roles: ['Supporting Actor']
            },
            {
                castName: 'Evan Papageorgiou',
                image: GhostTownCricketCinematographer,
                roles: ['Cinematographer']
            }
        ]
    },
    {
        filmName: "Let me Out",
        castName: 'Devanshi Srivastava',
        image: missingImage,
        roles: ['Director', 'Producer', 'Screenwriter', 'Cinematographer', 'Editor', 'Production Designer'],
        quote: `N/A`,
        bio: `An aspiring media student, who has a dream of becoming a reputed director one day.` ,
        socialLinks: {
            instagram: `Handle - @devanshii_srivastava`
        },
        otherCastAndCrews: [
            {
                castName: 'Amelie Devereux',
                image: LetMeOutLeadActor,
                roles: ['Lead Actor'],
                bio: `My name is Amélie Devereux, and I am an 18-year-old actress represented byJackie O Talent Agency, bringing a wealth
                of experience and a unique perspective to the world of performance.`
            },
            {
                castName: 'Claire Warrillow',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `Claire is a highly experienced Voice Over artist, including commercial and audio books.
                She is a trained and diverse Musical Theatre actor and professional singer.`
            },
            {
                castName: 'Theofano Tsentikopoulou',
                image: LetMeOutSoundDesigner,
                roles: ['Music Composer'],
                bio: `Passionate about the art of sound in cinema, I'm Theofano, a recent graduate with a degree in
                Audio Production. With a specialised focus on creating immersive and captivating audio experiences for film.`
            }
        ]
    },
    {
        filmName: "Mind the Cart (Mann ka Thela)",
        castName: 'Madhu Dhurve',
        image: MindTheCartDirector_MD,
        roles: ['Director', 'Screenwriter', 'Music Composer'],
        quote: `N/A`,
        bio: `Madhu has been working with ektara collective in the process of making films for a decade.
        She started when she was very young as a volunteer and over the years has become an integral part of the
        ektara collective, participating in various assisting capacities including production design, direction etc. This
        is her first short film. 
        Outside of her work with the Ektara Collective, Madhu works as a fiction and non-fiction writer and has had
        several of her short stories and news reports published over the years. She also sings and performs with
        various groups.
        Her understanding of the world and the society is shaped by her own experiences as an indigenous person.
        Her unique voice foregrounds the experiences of people belonging to marginal backgrounds in her stories and
        also creates an aesthetic that is grounded in reality and is not manufactured. Her storytelling is emotive and
        political at the same time, giving the readers /viewers an opportunity to explore alternate perspectives. Her
        stories need to be told and she has the passion to do so both as a writer and a filmmaker.

        Narendra Singh Pardhi: Narendra has a keen interest in creating visuals that are localized and rooted in
        contextual realities. His sense of aesthetic is informed from his experiences as a person who belongs to the
        Pardhi denotified tribe bringing in details that are often unseen or ignored by the mainstream.
        Pushpa: Pushpa has been pursuing formal training in Audiography. Sound has played a major roles in her life
        and when she attended a workshop with Ektara Collective, she recognised her interest in sound in film. She
        belongs to a working class family residing in an industrial area in Central India with high levels of
        environmental and noise pollution. She is committed to bringing to the foreground the voices and stories of
        the people who have to live in these hard conditions through her work and art.
        Together, Madhu, Narendra and Pushpa are the second generation of the "Ektara Collective," an
        independent and autonomous group of individuals dedicated to merging creative efforts and imagination.
        Our aim is to collaborate with both trained and untrained individuals to produce fiction films that are
        rooted in our realities and experiences, both in terms of content and aesthetics. Having previously
        assisted in Ektara's films, we have now taken on the responsibility of creating our own short stories.` ,
        socialLinks: {
            instagram: `ektaracollective.in`
        },
        // otherCastAndCrew: [
        //     {
        //         name: 'Ektara collective',
        //         image: missingImage,
        //         roles: ['Producer'],
        //         bio: `As an independent filmmaking collective, Ektara brings together diverse individuals, trained and
        //         untrained, to merge their creative abilities and to make and produce films that are informed by and reflect a
        //         common reality that emerges from our experiences, both in terms of content and aesthetics. Ektara has
        //         successfully produced short fiction, feature films, music videos and has also supported the dubbing of films
        //         from various languages into Hindi.`
        //     },
        //     {
        //         name: 'Shakha Pawar',
        //         image: missingImage,
        //         roles: ['Lead Actor'],
        //         bio: `Shakha is studying in the 7th grade. He belongs to the “Pardhi community”. This tribe was
        //         criminalized in 1871 by britishers. In 1952, this law was denotified. Still they are struggling to get their
        //         basic needs met.`
        //     },
        //     {
        //         name: 'Rinku Uike',
        //         image: mtcSupporting,
        //         roles: ['Supporting Actor'],
        //         bio: `She is a 34 year old woman from the “Ojha Gond” tribe. She lives in Gautam Nagar slum of
        //         Bhopal and is a single mother. To raise her two children, she works as a waste collector and actively
        //         works with the community in the Urban Labour Organization for the rights of every person living in the
        //         slums.`
        //     },
        //     {
        //         name: 'Shini JK',
        //         image: mtcEditor,
        //         roles: ['Editor'],
        //         bio: `Born in Thalassery, Kerala. After pursuing Bachelors in Aeronautical Engineering in
        //         Coimbatore, Tamil Nadu, she worked as a freelance writer before landing up at Film and Television
        //         Institute of India, Pune to pursue Editing.
        //         After graduating from FTII in 2016, she shifted to Mumbai and started working as an Editor. She began
        //         her career in the film industry (March 2017) as an Assistant Director in Malayalam Feature film Eeda to
        //         know how things work on shoots at a large scale before the footage comes to the suite. After Eeda,
        //         since September 2017, her sole focus is on Editing. Primarily edited short fictions,documentaries,
        //         sketch series and music videos.`
        //     },
        //     {
        //         name: 'Sanjeev Shri',
        //         image: missingImage,
        //         roles: ['Music Composer'],
        //         bio: `Sanjeev Shri is a flamenco guitarist. He has learnt this art form for many years and has
        //         traveled to Spain just to learn this art form. He learnt flamenco from Juan de la Moron (Moron family of
        //         flamenco). He has also been trained in Indian classical. His style of playing is Indian classical
        //         influenced, sensitive and quite improvisational in nature.`
        //     }
        // ]
    },
    {
        filmName: "Mind the Cart (Mann ka Thela)",
        castName: 'Pushpa',
        image: missingImage,
        roles: ['Director'],
        quote: `N/A`,
        bio: `Pushpa has been pursuing formal training in Audiography. Sound has played a major roles in her life
        and when she attended a workshop with Ektara Collective, she recognised her interest in sound in film. She
        belongs to a working class family residing in an industrial area in Central India with high levels of
        environmental and noise pollution. She is committed to bringing to the foreground the voices and stories of
        the people who have to live in these hard conditions through her work and art.
        Together, Madhu, Narendra and Pushpa are the second generation of the "Ektara Collective," an
        independent and autonomous group of individuals dedicated to merging creative efforts and imagination.
        Our aim is to collaborate with both trained and untrained individuals to produce fiction films that are
        rooted in our realities and experiences, both in terms of content and aesthetics. Having previously
        assisted in Ektara's films, we have now taken on the responsibility of creating our own short stories.` ,
        socialLinks: {
            instagram: `ektaracollective.in`
        },
        // otherCastAndCrew: [
        //     {
        //         name: 'Ektara collective',
        //         image: missingImage,
        //         roles: ['Producer'],
        //         bio: `As an independent filmmaking collective, Ektara brings together diverse individuals, trained and
        //         untrained, to merge their creative abilities and to make and produce films that are informed by and reflect a
        //         common reality that emerges from our experiences, both in terms of content and aesthetics. Ektara has
        //         successfully produced short fiction, feature films, music videos and has also supported the dubbing of films
        //         from various languages into Hindi.`
        //     },
        //     {
        //         name: 'Shakha Pawar',
        //         image: missingImage,
        //         roles: ['Lead Actor'],
        //         bio: `Shakha is studying in the 7th grade. He belongs to the “Pardhi community”. This tribe was
        //         criminalized in 1871 by britishers. In 1952, this law was denotified. Still they are struggling to get their
        //         basic needs met.`
        //     },
        //     {
        //         name: 'Rinku Uike',
        //         image: mtcSupporting,
        //         roles: ['Supporting Actor'],
        //         bio: `She is a 34 year old woman from the “Ojha Gond” tribe. She lives in Gautam Nagar slum of
        //         Bhopal and is a single mother. To raise her two children, she works as a waste collector and actively
        //         works with the community in the Urban Labour Organization for the rights of every person living in the
        //         slums.`
        //     },
        //     {
        //         name: 'Shini JK',
        //         image: mtcEditor,
        //         roles: ['Editor'],
        //         bio: `Born in Thalassery, Kerala. After pursuing Bachelors in Aeronautical Engineering in
        //         Coimbatore, Tamil Nadu, she worked as a freelance writer before landing up at Film and Television
        //         Institute of India, Pune to pursue Editing.
        //         After graduating from FTII in 2016, she shifted to Mumbai and started working as an Editor. She began
        //         her career in the film industry (March 2017) as an Assistant Director in Malayalam Feature film Eeda to
        //         know how things work on shoots at a large scale before the footage comes to the suite. After Eeda,
        //         since September 2017, her sole focus is on Editing. Primarily edited short fictions,documentaries,
        //         sketch series and music videos.`
        //     },
        //     {
        //         name: 'Sanjeev Shri',
        //         image: missingImage,
        //         roles: ['Music Composer'],
        //         bio: `Sanjeev Shri is a flamenco guitarist. He has learnt this art form for many years and has
        //         traveled to Spain just to learn this art form. He learnt flamenco from Juan de la Moron (Moron family of
        //         flamenco). He has also been trained in Indian classical. His style of playing is Indian classical
        //         influenced, sensitive and quite improvisational in nature.`
        //     }
        // ]
    },
    {
        filmName: "Mind the Cart (Mann ka Thela)",
        castName: 'Narendra singh pardhi',
        image: MindTheCartDirector_NSP,
        roles: ['Director', 'Cinematographer'],
        quote: `N/A`,
        bio: `Narendra has a keen interest in creating visuals that are localized and rooted in
        contextual realities. His sense of aesthetic is informed from his experiences as a person who belongs to the
        Pardhi denotified tribe bringing in details that are often unseen or ignored by the mainstream. 
        One of the first filmmakers from the Pardhi community he is based in Bhopal, Madhya
        Pradesh and began his filmmaking journey in 2021. He has worked on three major films and two
        documentaries, focusing on editing, cinematography, and direction. As a member of the Ektara
        Collective, he has honed his skills through various opportunities. His dedication to his craft and
        community is evident in his work, establishing him as a promising talent in Hindi film and community
        filmmaker.`,
        socialLinks: {
            instagram: `ektaracollective.in`
        },
        otherCastAndCrews: [
            {
                castName: 'Ektara collective',
                image: missingImage,
                roles: ['Producer'],
                bio: `As an independent filmmaking collective, Ektara brings together diverse individuals, trained and
                untrained, to merge their creative abilities and to make and produce films that are informed by and reflect a
                common reality that emerges from our experiences, both in terms of content and aesthetics. Ektara has
                successfully produced short fiction, feature films, music videos and has also supported the dubbing of films
                from various languages into Hindi.`
            },
            {
                castName: 'Shakha Pawar',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Shakha is studying in the 7th grade. He belongs to the “Pardhi community”. This tribe was
                criminalized in 1871 by britishers. In 1952, this law was denotified. Still they are struggling to get their
                basic needs met.`
            },
            {
                castName: 'Rinku Uike',
                image: MindTheCartSupporting,
                roles: ['Supporting Actor'],
                bio: `She is a 34 year old woman from the “Ojha Gond” tribe. She lives in Gautam Nagar slum of
                Bhopal and is a single mother. To raise her two children, she works as a waste collector and actively
                works with the community in the Urban Labour Organization for the rights of every person living in the
                slums.`
            },
            {
                castName: 'Shini JK',
                image: MindTheCartEditor,
                roles: ['Editor'],
                bio: `Born in Thalassery, Kerala. After pursuing Bachelors in Aeronautical Engineering in
                Coimbatore, Tamil Nadu, she worked as a freelance writer before landing up at Film and Television
                Institute of India, Pune to pursue Editing.
                After graduating from FTII in 2016, she shifted to Mumbai and started working as an Editor. She began
                her career in the film industry (March 2017) as an Assistant Director in Malayalam Feature film Eeda to
                know how things work on shoots at a large scale before the footage comes to the suite. After Eeda,
                since September 2017, her sole focus is on Editing. Primarily edited short fictions,documentaries,
                sketch series and music videos.`
            },
            {
                castName: 'Sanjeev Shri',
                image: missingImage,
                roles: ['Music Composer'],
                bio: `Sanjeev Shri is a flamenco guitarist. He has learnt this art form for many years and has
                traveled to Spain just to learn this art form. He learnt flamenco from Juan de la Moron (Moron family of
                flamenco). He has also been trained in Indian classical. His style of playing is Indian classical
                influenced, sensitive and quite improvisational in nature.`
            }
        ]
    },
    {
        filmName: "My Hands Are Shaking",
        castName: 'Natalia Sara Skorupa',
        image: MyHandsAreShakingDirector,
        roles: ['Director', 'Producer', 'Screenwriter', 'Lead Actor', 'Supporting Actor', 'Cinematographer', 'Editor', 'Music Composer', 'Production Designer'],
        quote: `N/A`,
        bio: `Natalia Sara Skorupa (she/her) – born in January 18, 1999 in Szczecin. Daughter of Beata and Jerzy,
        trained sociologist, filmmaker and curator. Pro- LGBTQ+ activist, singer and poet. In her artistic practices, she focuses
        on all kinds of audiovisual forms in the context of sexuality and willingly immerses herself in performative or
        documentary mediums, clearly exploring social, feminist, transgressive and radical topics using her own body. In 2022,
        she received a scholarship from the Minister of Culture and National Heritage, and in 2023, she received a scholarship
        from the Mayor of Szczecin.`,
        socialLinks: {
            instagram: `@artystka_ze_spalonego_teatruu`
        },
        otherCastAndCrews: []
    },
    {
        filmName: "The Garmentologist",
        castName: 'Debajit Banerjee',
        image: TheGarmentologistDirector,
        roles: ['Director', 'Producer', 'Screenwriter', 'Production Designer'],
        quote: `N/A`,
        bio: `https://www.djit.co.in`,
        socialLinks: {
            instagram: `https://www.djit.co.in`
        },
        otherCastAndCrews: [
            {
                castName: 'Rwik Dey',
                image: missingImage,
                roles: ['Lead Actor']
            },
            {
                castName: 'Ashok Roy',
                image: missingImage,
                roles: ['Supporting Actor']
            },
            {
                castName: 'Souvik Basu',
                image: missingImage,
                roles: ['Cinematographer']
            },
            {
                castName: 'Abhishek Pradhan',
                image: missingImage,
                roles: ['Editor'],
                
            },
            {
                castName: 'Mainak Mazoomdar',
                image: missingImage,
                roles: ['Music Composer']
            }
        ]
    },
    {
        filmName: "Lilith",
        castName: 'Dale Crawford',
        image: LilithDirector,
        roles: ['Director', 'Producer', 'Screenwriter', 'Editor'],
        quote: `The film is a showcase of complex, heartfelt performances. After shooting the final scene William Emmons said that normally he'd never be the same.`,
        bio: `Dale was born in Melbourne, Australia. He studied screenwriting at Swinburne University, Fine Art painting and
        photography at the Victorian College of the Arts, Cinema Studies at LaTrobe University, Cultural Studies and
        Philosophy at the University of Melbourne, Art Direction at Award School and Masters of Multimedia Design at
        Monash University. He established Moon Room Films to write and direct his first feature film Lilith. 
        Lilith is the first feature film written and directed by Dale Crawford. Inspired by true stories and personal
        experiences the film was a labour of love. It was entirely self-funded and took ten years work to complete.`,
        socialLinks: {
            instagram: `www.dalecrawford.org`
        },
        otherCastAndCrews: [
            {
                castName: 'Robert Chuter',
                image: missingImage,
                roles: ['Producer'],
                bio: `Robert Chuter is an award-winning theatre director.`
            },
            {
                castName: 'Marie-Therese Biornerud',
                image: LilithLeadM,
                roles: ['Lead Actor'],
                bio: `Worsebehaviour.`
            },
            {
                castName: 'William Emmons',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `The Gardener.`
            },
            {
                castName: 'Timothy Quabba',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Doctor Blake Mysteries.`
            },
            {
                castName: 'Richard Aspel',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Blue Healers.`
            },
            {
                castName: 'Lila Abello',
                image: missingImage,
                roles: ['Lead Actor']
            },
            {
                castName: 'Akosia',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `Thor: Love & Thunder`
            },
            {
                castName: 'Dale Crawford',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `Le Brea`
            },
            {
                castName: 'Jet Tranter',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `Occupation Rainfall`
            },
            {
                castName: 'Ryan Bown',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `Home & Away`
            },
            {
                castName: 'Rudi Siira',
                image: LilithCinematographer,
                roles: ['Cinematographer'],
                bio: `Rudi Siira won the Golden Tripod Award from the Australian Cinematographers Society for the
                feature documentary Geeta and the Silver Tripod Award for the feature film Paper Champions.`
            },
            {
                castName: 'Michael Cooper',
                image: missingImage,
                roles: ['Music Composer'],
                bio: `Michael Cooper is a multi-talented musician, producer, music director, composser and performer.`
            },
            {
                castName: 'Kim Triebsee',
                image: missingImage,
                roles: ['Production Designer'],
                bio: `Kim Triebsee is a Creative Director with a background in the fine arts, literature and fashion.`
            }
        ]
    },
    {
        filmName: "OPIA",
        castName: 'Cosmo Calman',
        image: OPIADirector,
        roles: ['Director', 'Screenwriter'],
        quote: `From humble beginnings to bittersweet ends, you can never be too sure “From humble beginnings to bittersweet ends, you can never be too sure
        how this thing we how this thing we call life goes. Film is just another form of expression. call life goes. Film is just another form of expression.
        But… I find myself lost in the challenge. But… I find myself lost in the challenge. I It’s not easy to go out and make t’s not easy to go out and make
        films and I find that a huge part of the allure. It’s difficult. It’s rewarding. films and I find that a huge part of the allure. It’s difficult. It’s rewarding.
        It’s a release. It’s everything you want it to be. It will punish you and it will It’s a release. It’s everything you want it to be. It will punish you and it will
        make you better. Some of the greatest moments aren’t scripted and that’s make you better. Some of the greatest moments aren’t scripted and that’s
        another thing I love. When people, cast or crew give you something of another thing I love. When people, cast or crew give you something of
        themselves that services the art. It grows and evolves into something far themselves that services the art. It grows and evolves into something far
        bigger.`,
        bio: `An emerging talent from the mighty Hawkes Bay. Cosmo cut his teeth in
        Wellington. A theatre technician by day and a freelance artist/writer by
        night. Cosmo studied at EIT Screen Production and Yoobee Colleges for a
        Bachelor of Animation, both times receiving awards for directing. With a
        few screenplays, a couple of novels and a comic book piled on his desk,
        several 48HR film fests and a handful of shorts under his belt, he has his
        sights set on the future of feature films. A few accolades for his
        screenplays, notably Storycamp 2022. Currently in pre-production he has
        another short with a wealth of writing material ready for publishing.
        Cosmo aims to keep his head down and foot on the gas.`,
        socialLinks: {
            instagram: `www.dreambig.net.nz or #Opia2023hb or #danielbettynz`
        },
        otherCastAndCrews: [
            {
                castName: 'Daniel Betty',
                image: OPIAProducer,
                roles: ['Producer', 'Production Designer'],
                bio: `Is a producer and director throughout New Zealand and Australia. He
                graduated with a Bachelor of Performing Arts from Unitec and has a
                post graduate diploma in secondary teaching from Massey university.
                
                Upon returning to Hawke’s Bay Daniel has been at the birth of many great
                artistic initiatives. He championed community and education in the
                Hawke’s Bay Arts Festival and Toitoi Hawke’s Bay Arts and Events Centre.
                He assisted in the establishment of Eastern Screen Alliance and
                developed Screen Central a collaborative hub for creative people. He sits
                on the Nga Toi Board the arts council for Hawke’s Bay and is passionate
                about building opportunities for creatives.
                
                Currently he is the Creative Director for Dream Big a production company
                that works on screen, on stage and on air. They have a social focus and
                champion creativity as a vehicle for social change.`
            },
            {
                castName: 'Yvonne Lorkin',
                image: missingImage,
                roles: ['Producer'],
                bio: `While Yvonne Lorkin is best known for her work in New Zealand’s wine
                media (Dish Magazine/NZ Herald/WineFriend.co.nz), prior to that her life
                revolved around the music industry, 95bFM, MTV, managing bands and in
                the production and publicity of tours, concerts and music videos. She’s a
                stage and screen actor, and the founder of major Auckland-based
                voiceover talent agency Bigmouth. She also founded Thirsty Media, a
                production company responsible for creating high-end food and wine
                video content for independent clients and the popular lifestyle TV series
                Thirsty Work (FoodTV + ThreeNow) which she produced, wrote and
                presented.
                
                A proud Hawkesbaylien, Yvonne has loved working on this art-soaked
                project with its incredibly talented local crew, and she’s excited to see the
                great relationships now being formed between local businesses and
                Hawke’s Bay’s screen industry professionals.`
            },
            {
                castName: 'Maia Bassett',
                image: OPIALead,
                roles: ['Lead Actor'],
                bio: `Mantra`
            },
            {
                castName: 'Noah Kaio',
                image: OPIASupport,
                roles: ['Supporting Actor'],
                bio: `The Stranger`
            },
            {
                castName: 'Gilly Lawrence',
                image: missingImage,
                roles: ['Cinematographer']
            },
            {
                castName: 'Frayne Dyke-Walker',
                image: missingImage,
                roles: ['Editor']
            },
            {
                castName: 'Hone Kawana Matene',
                image: missingImage,
                roles: ['Music Composer']
            },
            {
                castName: 'Halo Siloata',
                image: missingImage,
                roles: ['Music Composer']
            }
        ]
    },
    {
        filmName: "The Wandering Island",
        castName: 'Pablo Borges',
        image: missingImage,
        roles: ['Director', 'Screenwriter', 'Editor'],
        quote: `N/A`,
        bio: `Pablo Broges is a filmmaker and photojournalist born in Santa Cruz de Tenerife (Spain), in 1997.`,
        socialLinks: {
            instagram: `@paboborgesdl, @digital104_filmdistribution`

        },
        otherCastAndCrews: [
            {
                castName: 'Patricia Marti',
                image: missingImage,
                roles: ['Producer']
            },
            {
                castName: 'José Luis de Madariaga',
                image: missingImage,
                roles: ['Lead Actor']
            },
            {
                castName: 'Sergio Hernández',
                image: missingImage,
                roles: ['Supporting Actor']
            },
            {
                castName: 'Sergio Herrera',
                image: missingImage,
                roles: ['Cinematographer']
            },
            {
                castName: 'Ricardo Gonzáles',
                image: missingImage,
                roles: ['Music Composer']
            },
            {
                castName: 'Gabriel Vega',
                image: missingImage,
                roles: ['Production Designer']
            }
        ]
    },
    {
        filmName: "The Piccadilly: Restoring Her Former GLory",
        castName: 'Matthew Shannon',
        image: ThePicadillyDirector,
        roles: ['Director'],
        quote: `N/A`,
        bio: `Matt has a Bachelor of Arts Degree in Film, American and European History. He is a member of the Australian Directors Guild (ADG),
        Australian Editors Guild (ASE) and Australian Cinematographers Society. Matthew is an award-winning Director, Cinematographer and
        Editor.`,
        socialLinks: {
            instagram: `www.scarlettmedia.com.au`
        },
        otherCastAndCrews: [
            {
                castName: 'Peta Shannon',
                image: ThePicadillyProducer,
                roles: ['Producer'],
                bio: `Peta is an award-winning Producer, Writer, Actor, Dancer and with Screen Producers Association (SPA), Australian Writers' Guild (AWG) and
                Media Resource Centre memberships with a Bachelor of Arts Degree in Drama, Film and Creative Writing.`
            },
            {
                castName: 'Scott Hicks',
                image: ThePicadillyLead_Sch,
                roles: ['Lead Actor'],
                bio: `Scott Hicks: https://www.imdb.com/name/nm0382956/`
            },
            {
                castName: 'Sophie Hyde',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Sophie Hyde: https://www.imdb.com/name/nm1170520/?ref_=fn_al_nm_1`
            },
            {
                castName: 'Tilda Cobham-Hervey',
                image: ThePicadillyLead_Tch,
                roles: ['Lead Actor'],
                bio: `Tilda Cobham-Hervey: https://www.imdb.com/name/nm5315479/?ref_=fn_al_nm_1`
            },
            {
                castName: 'Maxx Corkindale',
                image: missingImage,
                roles: ['Cinematographer'],
                bio: `Maxx Corkindal: https://www.imdb.com/name/nm2663932/?ref_=fn_al_nm_1`
            },
            {
                castName: 'Matthew Shannon',
                image: missingImage,
                roles: ['Cinematographer'],
                bio: `Matthew Shannon: https://www.imdb.com/name/nm6367066/`
            },
            {
                castName: 'Dave Gregan',
                image: missingImage,
                roles: ['Cinematographer'],
                bio: `Dave Gregan: https://www.imdb.com/name/nm2962529/?ref_=ttfc_fc_cr`
            },
            {
                castName: 'Nic Begue',
                image: missingImage,
                roles: ['Editor'],
                bio: `Nicholas Begue: https://www.imdb.com/name/nm14240026/?ref_=ttfc_fc_cr`
            },
            {
                castName: 'Matthew Shannon',
                image: missingImage,
                roles: ['Editor']
            }
        ]
    },
    {
        filmName: "Sole Sista",
        castName: 'Emerie Boone',
        image: SoleSistaDirector,
        roles: ['Director', 'Screenwriter', 'Production Designer'],
        quote: `N/A`,
        bio: `Emerie is an 18 year old Australian/American director based in Sydney, Australia. She brings passion, enthusiasm and her own acting experience
        (as seen in her performance on ABC iview's TV show 'The PM's Daughter') to every project and has an incredible knack for bringing her vision to life
        on camera.`,
        socialLinks: {
            instagram: `@blackgirlsfilmcamp`
        },
        otherCastAndCrews: [
            {
                castName: 'Black Girls Film Camp',
                image: missingImage,
                roles: ['Producer'],
                bio: `Black Girls Film Camp, founded in 2021, is a non-profit organisation that sponsors young black female
                directors in creating their dream films across the world.`
            },
            {
                castName: 'Ashyln Ballard',
                image: SoleSistaLead,
                roles: ['Lead Actor'],
                bio: `Ashlyn Ballard is an 17 year old actress from the state of California. Ashlyn is a hardworking and
                talented individual who has a deep love for acting. She brings flare and creativity to every roles she's in.`
            },
            {
                castName: 'Clarissa Martinez Del Rio',
                image: SoleSistaSupporting_CM,
                roles: ['Supporting Actor']
            },
            {
                castName: 'Sage Olguin',
                image: missingImage,
                roles: ['Supporting Actor']
            },
            {
                castName: 'Chloe Voigt',
                image: missingImage,
                roles: ['Supporting Actor']
            },
            {
                castName: 'Shonda Porter',
                image: SoleSistaSupporting_SP,
                roles: ['Supporting Actor']
            },
            {
                castName: 'Tenika Pounce',
                image: SoleSistaSupporting_TP,
                roles: ['Supporting Actor']
            },
            {
                castName: 'Christine Wiggins',
                image: SoleSistaSupporting_CW,
                roles: ['Supporting Actor']
            },
            {
                castName: 'B.P. Edwards',
                image: SoleSistaCinematographer,
                roles: ['Cinematographer'],
                bio: `B.P. Edwards is a multi-hyphenate filmmaker, creative and entrepreneur from Pasadena, CA. B.P. was selected as a
                cinematographer for the inaugural Cohort of Color Creative's Find Your People Program.`
            },
            {
                castName: 'Jordan Knight',
                image: SoleSistaEditor,
                roles: ['Editor'],
                bio: `Jordan Knight is a KSU (Kennesaw State University) 2024 Graduate. She is dedicated to her craft and
                always actioning forward on how she can develop and positively impact her field of work as an editor.`
            },
            {
                castName: 'Georges Bizet',
                image: missingImage,
                roles: ['Music Composer'],
                bio: `Georges Bizet was a French composer of the Romantic era. Best known for his operas.`
            },
            {
                castName: 'Pyotr Ilyich Tchaikovsky',
                image: missingImage,
                roles: ['Music Composer'],
                bio: `Pyotr Ilyich Tchaikovsky was a Russian composer during the Romantic period. He was the first Russian composer whose music would make a lasting impression internationally.`
            },
            {
                castName: 'Antonio Vivaldi',
                image: missingImage,
                roles: ['Music Composer'],
                bio: `Antonio Lucio Vivaldi was an Italian composer, virtuoso violinist and impresario of Baroque music.`
            }
        ]
    },
    {
        filmName: "Papa Aur Smartphone",
        castName: 'Raj Sisodiaa',
        image: missingImage,
        roles: ['Director', 'Screenwriter', 'Production Designer'],
        quote: `N/A`,
        bio: `A young professional who hails from the spiritual city of lord krishna, Mathura, Uttar Pradesh, Raj has always thought about making a difference by his films, Raj Sisodiaa had started his career as an assistant director in movies like Tevar, Bhaagte raho before he got a chance to become the first assistant Director in the movie, Bhuj- The pride of india, starring, Ajay Devgn, Sanjay Dutt, Sonakshi Sinha and others. Raj has a habit of writing that makes him more lethal and creative as a director.`,
        socialLinks: {
            instagram: `https://www.instagram.com/officialrajsisodiaa/`,
            facebook: `https://www.facebook.com/profile.php?id=100008151524460`,
            linkedin: `https://www.linkedin.com/in/raj-sisodiaa-7a9577134/?originalSubdomain=in`
        },
        otherCastAndCrews: [
            {
                castName: 'Vandana Mishra',
                image: missingImage,
                roles: ['Producer'],
                bio: `She is a first time producer.`
            },
            {
                castName: 'Sanjay Mishra',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Sanjay Mishra is an Indian actor primarily working in the Hindi film and television industry. Mishra won the Best Actor award in the critic's category for Aankhon Dekhi at the Filmfare Awards in 2015. He was nominated for Masaan and Kamyab in the following years. He went on to win awards as the best actor for Aankhon Dekhi in 2014 and best-supporting actor for Masaan in 2015 at the Filmfare awards. He also received the Best Supporting actor (Male) award for Masaan at the Zee Cine Awards in 2016.`
            },
            {
                castName: 'Shriidhar Dubey',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `Shriidhar Dubey was born in Varanasi, Uttar Pradesh, India. He is known for Gangs of Wasseypur (2012), Raat Akeli Hai (2020) and Bholaa (2023).`
            },
            {
                castName: 'Anil Pingua',
                image: missingImage,
                roles: ['Cinematographer'],
                bio: `Anil Pingua is known for Papa Aur Smartphone and After That (Uske Baad) (2022).`
            },
            {
                castName: 'Vinay Pal',
                image: missingImage,
                roles: ['Editor'],
                bio: `Vinay Pal is known for Jazbaa (2015), Yeh Hai India (2017) and Action Jackson (2014).`
            },
            {
                castName: 'Justin Jose',
                image: missingImage,
                roles: ['Music Composer'],
                bio: `Justin Jose is known for Minnal Murali (2021), Bajirao Mastani (2015) and Uri: The Surgical Strike (2019).`
            },
            {
                castName: 'Krishna Thakur',
                image: missingImage,
                roles: ['Production Designer'],
                bio: `Krishna Thakur is an Art Director and Production Designer based at Mumbai. Krishna Thakur is known for The Kashmir File, Holiday (2014), Badlapur (2015), The Second Best Exotic Marigold Hotel (2015) and Chhichhore (2019) And many more.. He has been working since year 2011, in the Bollywood industry. He is a post graduate from the prestigious Film and Television Institute of India FTII class of 2009- 2011, he has established himself as a prominent figure in the world of cinema, Specialising in the roles of Art Director and Production Designer. He has creative expertise in a diverse range of projects, including feature films, web series, and compelling advertising campaigns.`
            }
        ]
    },
    {
        filmName: "WE DON'T SAY RETARD ANYMORE",
        castName: 'Tez Frost',
        image: missingImage,
        roles: ['Director', 'Producer', 'Screenwriter', 'Editor'],
        quote: `N/A`,
        bio: `Guinness World Record breaking director, former Disney writer.`,
        socialLinks: {
            facebook: `https://www.facebook.com/wedontsayretardanymore`
        },
        otherCastAndCrews: [
            {
                castName: 'Pekka Luodeslampi',
                image: WDSRALead,
                roles: ['Lead Actor'],
                bio: `Guinness World Record breaking disabled performer.`
            },
            {
                castName: 'Courtney McCrea',
                image: WDSRASupporting,
                roles: ['Supporting Actor'],
                bio: `Cosplay specialist from Northern Ireland.`
            },
            {
                castName: 'Aaro Alhonen',
                image: missingImage,
                roles: ['Cinematographer']
            },
            {
                castName: 'Markus Kellow',
                image: missingImage,
                roles: ['Music Composer']
            }
        ]
    },
    {
        filmName: "Vanavaa",
        castName: 'Mr. Anup Nilkumar Jatratkar',
        image: missingImage,
        roles: ['Director', 'Screenwriter'],
        quote: `N/A`,
        bio: `Directed more than 30 short films. Just release one marathi film named 'Gaabh'.`,
        socialLinks: {
            instagram: `www.anupjatratkar.com`
        },
        otherCastAndCrews: [
            {
                castName: 'Mr. Machhindra Baban Kamble',
                image: missingImage,
                roles: ['Producer']
            },
            {
                castName: 'Dr. Pramod Jyotiram kasabe',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Assistant Professor - Manoscrence & Bio`
            },
            {
                castName: 'Mr. Ananda Hable',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `Actor, Anti-addiction campaigner.`
            },
            {
                castName: 'Mr. Anup Nilkumar Jatratkar',
                image: missingImage,
                roles: ['Cinematographer'],
                bio: `As given in the back.`
            },
            {
                castName: `Priydarshan's Atelier Nipani`,
                image: missingImage,
                roles: ['Editor'],
                bio: `An Audio & Video studio.`
            }
        ]
    },
    {
        filmName: "Raj Kapoor's Loni Tunes",
        castName: 'Nadir Ahmed',
        image: missingImage,
        roles: ['Director'],
        quote: `N/A`,
        bio: `Its his first film.`,
        socialLinks: {
            instagram: `Djproductions.co.in`
        },
        otherCastAndCrews: [
            {
                castName: 'Devendra Shivaji Jadhav',
                image: missingImage,
                roles: ['Producer'],
                bio: `diproductions.co.in`
            },
            {
                castName: 'Toshik',
                image: missingImage,
                roles: ['Cinematographer']
            },
            {
                castName: 'Raunak',
                image: missingImage,
                roles: ['Cinematographer']
            }
        ]
    },
    {
        filmName: "WHERE I AM",
        castName: 'Nadir Ahmed',
        image: missingImage,
        roles: ['Director'],
        quote: `N/A`,
        bio: `Its his first film.`,
        socialLinks: {
            instagram: `Djproductions.co.in`
        },
        otherCastAndCrews: [
            {
                castName: 'Devendra Shivaji Jadhav',
                image: missingImage,
                roles: ['Producer'],
                bio: `diproductions.co.in`
            },
            {
                castName: 'Toshik',
                image: missingImage,
                roles: ['Cinematographer']
            },
            {
                castName: 'Raunak',
                image: missingImage,
                roles: ['Cinematographer']
            }
        ]
    },
    /*Wil updated from here*/ 
    {
        filmName: "Why Wait",
        castName: 'Marie-hélène Grenier',
        image: missingImage,
        roles: ['Director', 'Screenwriter'],
        quote: `N/A`,
        bio: `N/A`,
        socialLinks: {
            website: `2squarefilms.com`
        },
        otherCastAndCrews: [
            {
                castName: 'Stéphane Landry',
                image: missingImage,
                roles: ['Producer'],
                bio: 'Producer since 2005',
            },

            {
                castName: 'Pierre Alexandre Coté',
                image: missingImage,
                roles: ['Cinematographer'],
            },
            {
                castName: `Josée Trotier`,
                image: missingImage,
                roles: ['Editor'],
            }
        ]
    },
    {
        filmName: "The pod",
        castName: 'MNaveen Shukla',
        image: ThePodDirector,
        roles: ['Director', 'Screenwriter','Producer','Cinematographer','Editor','Production Designer'],
        quote: `N/A`,
        bio: `N/A`,
        socialLinks: {
            instagram: ``
        },
        otherCastAndCrews: [
            {
                castName: 'Hem Tiwary',
                image: ThePodProducer,
                roles: ['Producer', 'Lead Actor'],
            },
            {
                castName: 'Jaya Berged',
                image: missingImage,
                roles: ['Supporting Actor'],

            },
            {
                castName: 'Kaveri Tiwary',
                image: missingImage,
                roles: ['Supporting Actor'],
            },
        ]
    },
    {
        filmName: "The Hero Of Adelaide",
        castName: 'Zakariya Muhammad Ilias',
        image: TheHeroOfAdelaideDirector,
        roles: ['Director', 'Screenwriter', 'Producer','Cinematographer', 'Editor', 'Production Designer'],
        quote: `N/A`,
        bio: `
            17-year-old Australian homeschooler, loves filmmaking, carving wood, and eating food.
            When an agent breaks into the home of none other than Dr. Alfred in an attempt to steal some valuable information, 
            but what he stumbles on changes everything.
        `,
        socialLinks: {
            youtube: `@DMSProductions-hc9qp`
        },
        otherCastAndCrews: [
            {
                castName: 'Reeve Jansen',
                image: TheHeroOfAdelaideLead,
                roles: ['Lead Actor'],
                bio: `15 years old, passionate about acting for film, he also enjoys playing vieo games, writing, and editing.`
            },
            {
                castName: 'Jake Mccormack',
                image: TheHeroOfAdelaideSupporting,
                roles: ['Supporting Actor'],
                bio :'14 years old, multi award winning filmmaker, and is intrested in acting as a career. '
            },
            {
                castName: 'Karmi Wingfield',
                image: TheHeroOfAdelaideCinema,
                roles: ['Cinematographer'],
                bio: `Passionate photographer, studying makeup, and enjoys making short films.`
            },
        ]
    },
    {
        filmName: "Sex For Survival",
        castName: 'Jorge Antonio Gonzalez',
        image: missingImage,
        roles: ['Director'],
        quote: `N/A`,
        bio: `
            Director of more than 30 short documentaries and winner of 18 Suncoast Emmy Awards.
            More than 25 years of experience in the industry. Nominated for the 2024 Goya Awards as 
            Producer of the film SIMÓN (top 10 worldwide on Netflix and winner of 20 international awards).
        `,
        socialLinks: {
            instagram: `@SEXOPORSUPERVIVENCIA`
        },
        otherCastAndCrews: [
            {
                castName: 'Luis Olavarrieta',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Very well-known Venezuelan journalist with 4 million followers on Instagram. Suncoast Emmy winner for other documentaries.`
            },
            {
                castName: 'Boris Urrutia',
                image: missingImage,
                roles: ['Editor'],
                bio: `Award-winning editor and director with more than 25 years of experience. Winner of Emmy International, Promax/BDA, and Telly Awards.`
            }
        ]
    },
    {
        filmName: "Parole",
        castName: 'Param Walia',
        image: ParoleDirector,
        roles: ['Director', 'Screenwriter',],
        quote: `N/A`,
        bio: `
            Param Walia is an Australian film director and writer of Indian origin who hails from Kota, Rajasthan, India.
            Writer/Director - (Phantasm, Sweet Tooth)
            Assistant Director - (My Melbourne - Feature)
            Executive Producer - The Last Surprise
        `,
        socialLinks: {
            instagram: 'https://www.instagram.com/iparamwalia/'
        },
        otherCastAndCrews: [
            {
                castName: 'Mitchell Holland',
                image: missingImage,
                roles: ['Lead Actor'],
            },
            {
                castName: 'Christopher Kirby',
                image: missingImage,
                roles: ['Supporting Actor'],
            },
            {
                castName: 'Nicholaus Burkitt',
                image: missingImage,
                roles: ['Cinematographer'],
            },
            {
                castName: 'Andrew Smith',
                image: missingImage,
                roles: ['Editor'],
            },
            {
                castName: 'Joel Armour',
                image: missingImage,
                roles: ['Music Composer'],
            },
            {
                castName: 'Anh Vuong',
                image: missingImage,
                roles: ['Production Designer'],
            }
        ]
    },
    {
        filmName: "B for naoo",
        castName: 'Roohi Kashfi',
        image: BForNaooDirector,
        roles: ['Director', 'Producer', 'Screenwriter'],
        quote: `N/A`,
        bio: `
            Award-winning filmmaker from the Federally Administered Tribal Areas (FATA) of Pakistan.
            Graduate of New York Film Academy Australia and National College of Arts Lahore, Pakistan.
        `,
        socialLinks: {
            instagram: 'https://www.instagram.com/roohikashfi?igsh=ZDE5aHZpNTU5Z3ls&utm_source=qr',
            facebook: 'https://www.facebook.com/roohikashfiofficial?mibextid=LQQJ4d'
        },
        otherCastAndCrews: [
            {
                castName: 'Sajjad Gohar',
                image: missingImage,
                roles: ['Cinematographer', 'Production Designer'],
                bio: `Visual communication designer and production designer.`
            },
            {
                castName: 'Ahmed Ali Shah',
                image: missingImage,
                roles: ['Editor'],
                bio: `Pakistani film director, writer, and editor. Owner of Frontrow Studio with several national and international commercial projects. Currently working with HUM TV as a producer.`
            },
            {
                castName: 'Qais Ahmed Kashfi',
                image: missingImage,
                roles: ['Music Composer'],
                bio: `
                    Musician, recording engineer, lyricist, composer, and rapper. 
                    Currently working as a sound engineer at Coke Studio, Pakistan.
                `
            },
            {
                castName: 'Mehdi Hussain',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `
                    1st time actor. He has written, composed, and sung many music projects.
                    Actor/filmmaker from FATA Tribal areas of Pakistan.
                `
            },
            {
                castName: 'Ziyad Ahmed Tariq',
                image: missingImage,
                roles: ['Production Designer'],
                bio: `
                    U.S.A based film director, originally from Karachi, Pakistan. 
                    Worked on several national and international projects.
                `
            },
            {
                castName: 'Raja Roy Diyach Thebo',
                image: missingImage,
                roles: ['Cinematographer'],
                bio: `Owner of Frontrow Studio. He has done several commercial projects nationally and internationally.`
            }
        ]
    },
];


type MovieDict = {
    [key: string]: {
        movieName: string;
        casts: Partial<MovieCast>[];
    };
};

const createMoviesCastList = (casts: MovieCast[]) => {
    if (!Array.isArray(casts)) {
        console.error("casts is not an array or is undefined");
        return [];
    }

    // Create a dictionary to merge movies by name
    const movieDict: MovieDict = {};

    casts.forEach(movie => {
        if (!movieDict[movie.filmName]) {
            movieDict[movie.filmName] = {
                movieName: movie.filmName,
                casts: []
            };
        }

        // Add the main cast (e.g., Director, Producer, etc.)
        if (movie.castName && Array.isArray(movie.roles)) {
            movieDict[movie.filmName].casts.push({
                castName: movie.castName,
                roles: movie.roles.join(", "),
                bio: movie.bio || '',
                image: movie.image || ''
            });
        } else {
            console.warn(`Missing name or roles for the movie: ${movie.filmName}`);
        }

        // Add the other cast and crew members
        if (Array.isArray(movie.otherCastAndCrews)) {
            movie.otherCastAndCrews.forEach(castMember => {
                movieDict[movie.filmName].casts.push({
                    castName: castMember.castName,
                    roles: Array.isArray(castMember.roles) ? castMember.roles.join(", ") : castMember.roles,
                    bio: castMember.bio || '',
                    image: castMember.image || ''
                });
            });
        }
    });

    // Convert the dictionary back into an array
    return Object.values(movieDict);
};



export default casts;
export { createMoviesCastList };

