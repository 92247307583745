import {ChangeEventHandler } from "react"

type SponsorUsFieldProps = {
    id: string;
    type: string;
    label: string;
    placeholder?: string;
    value: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    fieldError: boolean;
    errorText: string;
    isTextArea?: boolean;
    className?: string;
}

const SponsorUsField = ({...props} : SponsorUsFieldProps) => {    
    return(
        <div className={props.className}>
            <label htmlFor={props.id}
                className="block my-2 text-left text-sm font-medium text-gray-900 form-headers">
                {props.label} <span className='text-red-600'>*</span>
            </label>
            {props.isTextArea && props.onChange ?
                <textarea
                    className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 form-input ${props.fieldError ? 'border-red-500 focus:outline-none' : ''}`}
                    id={props.id}
                    name={props.id}
                    value={props.value}
                    onChange={props.onChange as unknown as ChangeEventHandler<HTMLTextAreaElement>}
                    placeholder={props.placeholder}
                    required 
                /> :
                <input type={props.type}
                    className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 form-input ${props.fieldError ? 'border-red-500 focus:outline-none' : ''}`}
                    id={props.id}
                    name={props.id}
                    value={props.value}
                    onChange={props.onChange}
                    placeholder={props.placeholder}
                    required 
                /> 
            }
            {props.fieldError &&
                <p style={{ fontSize: '13px', fontWeight: 'normal', color: 'red', paddingTop: '5px' }}>
                    {props.errorText}
                </p>}
        </div>
    )   
}

export default SponsorUsField;