import React from 'react';
import './Partners.css';
import Navbar from "../Components/Navbar/Navbar";
import Footer from '../Components/Footer/Footer';

import { motion } from 'framer-motion';
import { PartnersBackground } from '../Data/export_images';

const Partners = () => {
    const partnersData = [
        {
            alt: 'RMIT',
            description: 'RMIT University',
            url: 'https://www.rmit.edu.au/'
        },
        {
            alt: 'TORRENS',
            description: 'Torrens University Australia',
            url: 'https://torrens.edu.au/'
        },
        {
            alt: 'FORDHAM PR',
            description: 'Fordham PR',
            url: 'http://www.fordhampr.ca/introducing-the-international-film-festival-of-australia/'
        },
        {
            alt: 'FIFTY ACRES',
            description: 'Fifty Acres',
            url: 'https://fiftyacres.com.au/?utm_source=google&utm_medium=cpc&utm_campaign=PPC_Feb_June_22&gad_source=1&gbraid=0AAAAAobAlNbsvWs0zY9mgmumg2WE3dMIf&gclid=CjwKCAjw8fu1BhBsEiwAwDrsjCq_Iy_CI-cUlXdeGY_m7QnK5etUMJ2eQQJ9IU6C9bSWVCwvohBaZhoCqyYQAvD_BwE'
        },
        {
            alt: 'Embajada de la República de Costa Rica ',
            description: 'Embajada de la República de Costa Rica ',
            url: ''
        },
        {
            alt: 'Istituto Italiano di Cultura di Melbourne',
            description: 'Istituto Italiano di Cultura di Melbourne',
            url: ''
        },
        {
            alt: 'Consulate of the Republic of Korea',
            description: 'Consulate of the Republic of Korea',
            url: ''
        }

    ];

    return (
        <div className="partners-container">
            <Navbar />
            <motion.main
                initial={{ opacity: 0, x:-150 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1.5, delay: 1 }}
            >
                <header className='relative flex items-center justify-center h-44 text-white'>
                    <h1 className='z-20 shadow-2xl shadow-white rounded-lg px-5 py-2 font-bold text-4xl'>PARTNERS</h1>
                    <div className='absolute z-10 inset-0 bg-black opacity-50'></div>
                    <img src={PartnersBackground} className='absolute top-0 h-full w-full object-cover'></img>
                </header>

                <section className="partners-section">
                    <div className="partners-grid">
                        {partnersData.map((partner, index) => (
                            <a href={partner.url} target="_blank" rel="noopener noreferrer" key={index} className="partner-link">
                                <div className="partner">
                                    <p>{partner.description}</p>
                                </div>
                            </a>
                        ))}
                    </div>
                </section>
            </motion.main>
            
            <Footer />
        </div>
    );
};

export default Partners;

