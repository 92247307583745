import { motion } from "framer-motion";
import { Film } from "../../Data/films";

type FilmCardProps = {
    index: number;
    handleFilmHover: (film: Film) => void;
    film: Film;
    handleMouseLeave: () => void;
    handleFilmClick: (film: Film) => void;
    fadeInAnimationVariants: {
      initial: { opacity: number; y: number; };
      animate: { opacity: number; y: number; };
    };
    hoveredFilm: Film | null;
  };

function FilmCard({...props} : FilmCardProps) {
    return <motion.div
        key={props.index}
        onMouseEnter={() => props.handleFilmHover(props.film)}
        onMouseLeave={props.handleMouseLeave}
        onClick={() => props.handleFilmClick(props.film)}
        className="submission hover:shadow-xl hover:shadow-white"
        variants={props.fadeInAnimationVariants}
        initial={{ opacity: 0, x: -150 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
    >
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: props.hoveredFilm === props.film ? 1 : 0 }}
            transition={{ duration: 0.3 }}
            className="movieinfo"

        >
            <h1>{props.film.name.toUpperCase()}</h1>
            <p>
                {Array.isArray(props.film.director)
                    ? props.film.director.map((dir, index) => <span key={index}>{dir.name}</span>)
                    : props.film.director.name}
            </p>
        </motion.div>
        <img src={props.film.poster} alt={`${props.film.name} Poster`} className="film-poster" />
    </motion.div>;
}

export default FilmCard;